import { Grid, Typography } from "@mui/material";
import Instructions from "./Instructions";
import Form from "./Form";

const Discord = () => {
    return (
        <Grid container flexGrow="1" sx={{width: '100%'}}>
            <Instructions />
            <Form />
        </Grid>
    );
}

export default Discord;
