import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';

const AudioButton = () => {
  const audio = useSelector((state) => state.worldbook.audio);
  let newAudio = document.getElementById(`audio-${audio}`)
  let playSound = () => {
    if (!newAudio) {
      newAudio = document.createElement('audio')
      newAudio.id = `audio-${audio}`;
      const newSource = document.createElement('source')
      newSource.type = 'audio/mpeg'
      newSource.src = `/audio/${audio}.mp3`
      newAudio.append(newSource)
    }
    newAudio.play()
  }

  return (
    <>
      <IconButton onClick={playSound} sx={{ height: '40px', margin: '8px' }}>
        <VolumeUpIcon />
      </IconButton>
    </>
  )
}

export default AudioButton;
