import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { navMap } from "./Navigation";
import { setPageTitle } from "../../redux/app";

const NavigateListener = () => {
  const history = useHistory();
  const location = useLocation();
  const path = useSelector(state => state.app.path);
  const dispatch = useDispatch();
  window.goBack = history.goBack
  
  useEffect(() => {
    let title = 'ProfDeCube';
    for (const navItem of Object.values(navMap)) {
      if (location.pathname === navItem.path) {
        title = navItem.title;
      }
    }
    if(!location.pathname.startsWith("/worldbook")) dispatch(setPageTitle(title));
    
  }, [path, dispatch, location.pathname]);

  return null
}

export default NavigateListener;
