import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  gameId: null,
  game: {},
  data: {},
}

export const boardgameDialogSlice = createSlice({
  name: 'boardgameDialog',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setGame: (state, action) => {
      state.game = action.payload;
      state.open = true;
    },
    setData: (state, action) => {
      state.data = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setOpen, setData, setGame } = boardgameDialogSlice.actions

export default boardgameDialogSlice.reducer