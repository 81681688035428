import React from 'react';
import { Typography, Grid, Card } from "@mui/material";
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setPageTitle } from '../../redux/app';
import AudioButton from '../common/AudioButton';
import { setAudio } from '../../redux/worldbook';
import Link from "../common/Link";



const WorldbookPage = ({ currentPage }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPage.title === 'Worldbook') {
      dispatch(setPageTitle('Worldbook'));
    } else {
      dispatch(setPageTitle(`Worldbook: ${currentPage.title}`));
    }
    if (currentPage.pronunciation) dispatch(setAudio(currentPage.pronunciation))
  }, [currentPage.pronunciation, currentPage.title, dispatch])

  let breaks = 0;
  const replaceLinks = (text) => {
    const matches = [...text.matchAll(/<(.*?)\|(.*?)>|\n/g)]
    if (matches.length) {
      const textBits = text.split(/<.*?\|.*?>|\n/g);
      const textArray = [textBits.shift()];
      for (let matchIndex in matches) {
        const match = matches[matchIndex];
        if (match[0] === '\n') {
          textArray.push(<br key={`break-${breaks}`} />);
          breaks++
        } else {
          const link = (<Link key={`link-${match[1]}-${matchIndex}`} underlined to={match[1]}>
            {match[2]}
          </Link>)
          textArray.push(link)
        }

        textArray.push(textBits.shift())
      }
      return textArray;
    }
    return text;
  }

  return (
    <Card sx={{ margin: '12px', padding: '12px', width: '100%' }}>
      <Grid>
        <Grid container>
          {currentPage.title && <Typography variant="h3">{currentPage.title}</Typography>}
          {currentPage.pronunciation && <AudioButton srcFile={currentPage.pronunciation} />}
        </Grid>
        {currentPage.paragraphs &&
          currentPage.paragraphs.map((paragraph, textIndex) => {
            return (
              <Grid container key={`para-${textIndex}`} sx={paragraph.gridSx}>
                {paragraph.image && <img style={paragraph.image.sx} src={paragraph.image.src} alt={paragraph.image.alt}></img>}
                <Grid>
                  {paragraph.title && <Typography variant="h5">{paragraph.title}</Typography>}
                  {paragraph.text && <Typography sx={{ paddingBottom: '12px' }}>{replaceLinks(paragraph.text)}</Typography>}
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
    </Card >

  );
}

export default WorldbookPage;
