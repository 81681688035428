import { Button, Card, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const styles = {
  cardStyles: {
    maxWidth: 'fit-content',
    hight: 'fit-content',
    padding: '16px'
  },
  grid: {
    height: 'calc(100vh - 64px)',
  },
  question: {
    marginBottom: '8px'
  }
}

const Question = () => {
  const history = useHistory();
  const submitAnswer = () => {
    history.push('/qotd/answer')
  }
  return (
    <Grid container justifyContent="center" alignItems="center" sx={styles.grid}>
      <Card sx={styles.cardStyles}>

        <Typography sx={styles.question}>
          How much do you hate Joy?
        </Typography>
        <RadioGroup>
          <FormControlLabel value="1" control={<Radio />} label="Not Much" />
          <FormControlLabel value="2" control={<Radio />} label="Quite A Bit" />
          <FormControlLabel value="3" control={<Radio />} label="Like, A Lot" />
          <FormControlLabel value="4" control={<Radio />} label="She's The Bane Of My Existance" />

        </RadioGroup>
        <Grid container justifyContent="flex-end">
          <Button variant="contained" onClick={submitAnswer}>Submit</Button>
        </Grid>
      </Card>
    </Grid>
  );
}

export default Question;
