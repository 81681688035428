import { Typography } from "@mui/material";

const Home = () => {

  return (
    <Typography>
      Hello World
    </Typography>
  );
}

export default Home;
