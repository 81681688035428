import React, { useState } from 'react';
import { Grid, Button } from "@mui/material";
// import worldbookJson from './worldbookJson';
import Breadcrumbs from './Breadcrumbs';
import WorldbookNavigation from "./WorldbookNavigation";
import WorldbookPage from "./WorldbookPage";
import WorldbookPageEdit from "./WorldbookPageEdit";
import CreateNewPage from "./CreateNewPage";
import { useLocation } from "react-router-dom";
import { fetchWorldbookData, updatehWorldbookData } from '../../service/backendService.js'
import { setWorldbookJson } from '../../redux/worldbook';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';

const Worldbook = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const userDetails = useSelector(state => state.app.user);
  const isAdmin = userDetails && userDetails['https://ProfDeCube.com'].roles.includes('ADMIN');
  const [edit, setEdit] = useState(false);
  const updateWorldbookData = async () => {
    const worldbookResponse = await fetchWorldbookData()
    if (worldbookResponse) dispatch(setWorldbookJson(worldbookResponse))
  }
  const worldbookJson = useSelector((state) => state.worldbook.json)
  useEffect(() => {
    setEdit(false);
  }, [location])
  if (worldbookJson === null) {
    updateWorldbookData();
    return null;
  }

  const subpaths = location.pathname.toLowerCase().split("/").filter((subpath) => subpath.length);
  let currentInfo = worldbookJson
  let error = false;
  let canCreate = false;
  for (const subpath of subpaths) {
    if (currentInfo.children && currentInfo.children[subpath]) {
      currentInfo = currentInfo.children[subpath]
    } else {
      error = true;
      if (subpath === subpaths[subpaths.length - 1] && isAdmin) canCreate = true;
      console.error('Worldbook page does not exist')
      break
    }
  }

  const handleSave = async (paragraphs, title, perms) => {
    if (paragraphs) {

      const worldbookCopy = JSON.parse(JSON.stringify(worldbookJson), false)
      let currentPage = worldbookCopy
      for (const subpath of subpaths) {
        if (currentPage.children && currentPage.children[subpath]) {
          currentPage = currentPage.children[subpath]
        } else {
          error = true;
          break
        }
      }
      currentPage.paragraphs = paragraphs
      currentPage.title = title
      if(perms.length) {
        currentPage.hidden = perms
      } else {
        delete currentPage.hidden
      }
      const update = await updatehWorldbookData(worldbookCopy);
      if (update) {

        dispatch(setWorldbookJson(worldbookCopy))
        setEdit(false)
      }
    } else {
      setEdit(false)
    }
  }

  const handleNew = async (data) => {
    const worldbookCopy = JSON.parse(JSON.stringify(worldbookJson), false)
    let currentPage = worldbookCopy
    for (const subpath of subpaths) {
      if (currentPage.children && currentPage.children[subpath]) {
        currentPage = currentPage.children[subpath]
      } else {
        break
      }
    }
    currentPage.children = data.children
    const update = await updatehWorldbookData(worldbookCopy);
    if (update) {

      dispatch(setWorldbookJson(worldbookCopy))
      setEdit(false)
    }
  }
  return (
    <Grid container direction="column">
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{
        padding: '12px',
        paddingBottom: 0
      }}>
        <Breadcrumbs subpaths={subpaths} />
        {!edit && isAdmin && <Button size="small" onClick={() => { setEdit(true) }}>EDIT</Button>}
      </Grid>
      <Grid container wrap="nowrap">
        {!error && !edit && <WorldbookNavigation children={currentInfo.children} hide={edit} />}

        {edit &&
          <WorldbookPageEdit currentPage={currentInfo} key={location.pathname.toLowerCase()} setEdit={handleSave} adminEdit={isAdmin} />
        }
        {
          !edit && !error && <WorldbookPage currentPage={currentInfo} />
        }
        {canCreate &&
          <CreateNewPage
            currentPage={currentInfo}
            newPageSubpath={subpaths[subpaths.length - 1]}
            handleSave={handleNew}
          />
        }
      </Grid>
    </Grid>
  );
}

export default Worldbook;
