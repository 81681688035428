
import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import Themes from "./Themes";


const Auth = () => {

  return (
    <Auth0Provider
      domain="profdecube.eu.auth0.com"
      clientId="cIzKjgTtGH9lxqDoBa7j5qzItayLrInz"
      redirectUri={window.location.origin}
      audience="https://profdecube.eu.auth0.com/api/v2/"
      scope="read:current_user update:current_user_metadata roles Roles"
    >
      
      <Themes />
    </Auth0Provider>
  );
}

export default Auth;
