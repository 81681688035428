import seed from 'seed-random';

const seedString = 'test'

export const randomInt = (start, end, inclusive = true) => {
  const realEnd = (inclusive) ? end + 1 : end;
  const realStart = (inclusive) ? start : start + 1;
  const randomFloat = Math.random();
  const multipliedRandomFloat = randomFloat * (realEnd - realStart);
  return realStart + Math.floor(multipliedRandomFloat);
}

export const reseed = (newSeedString = seedString) => {
  seed(newSeedString, { global: true })
}

export const createSeed = (complexity = 6) => {
  let seedString = ''
  for (let i = 0; i < complexity; i++) {
    const num = Math.floor(Math.randomBackup() * 36)
    seedString += num.toString(36);
  }
  return seedString.toUpperCase();
}