export const API_BASE = `/api`
export const endpoints = {
  thing: '/thing/{thingId}',
  allGames: '/allGames',
  updateGames: '/updateGames',
  worldbook: '/worldbook',
  poker: '/poker?card1={card1}&card2={card2}&card3={card3}&card4={card4}',
  qotd: '/qotd/{qora}/{date}',
  scrape: '/scrape',
  download: '/download/{accessKey}'
}

export const urlBuild = (endpointType, paramMap) => {
  const urlBase = localStorage.getItem('apiOverride') || API_BASE;
  let endpoint = endpoints[endpointType]
  const endpointParams = [...endpoint.matchAll(/\{(.*?)\}/g)];
  endpointParams.forEach((endpointParam) => {
    endpoint = endpoint.replace(endpointParam[0], paramMap[endpointParam[1]])
  })
  return urlBase + endpoint
}

window.urlBuild = urlBuild;