import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  json: null,
  audio: null,
}

export const worldbookSlice = createSlice({
  name: 'worldbook',
  initialState,
  reducers: {
    setWorldbookJson: (state, action) => {
      state.json = action.payload;
    },
    setAudio: (state, action) => {
      state.audio = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setWorldbookJson, setAudio } = worldbookSlice.actions

export default worldbookSlice.reducer