import React from 'react';
import { Typography, Grid } from "@mui/material";
import Link from "../common/Link";
import { goto } from "../../redux/app";
import { useDispatch } from "react-redux";



const Breadcrumbs = ({ subpaths }) => {
  const dispatch = useDispatch();
  const breadCrumbNavigate = (event, path) => {
    dispatch(goto({ path, title: 'Worldbook' }))
  }

  return (
    <Grid container sx={{width: 'fit-content'}}>
      {
        subpaths.map((subpath, index) => {
          const linkPath = `/${subpaths.slice(0, index + 1).join("/")}`
          const last = subpaths.length === index + 1
          return (
            <Typography key={linkPath}>
              {
                (!last)
                  ? (
                    <Link underlined to={linkPath} onClick={(event) => breadCrumbNavigate(event, linkPath)}>
                      {subpath}
                    </Link>
                  )
                  : subpath
              }
              {!last && <Typography component="span" sx={{margin: '0 4px'}}>{">"}</Typography>}
            </Typography>
          )
        })
      }
    </Grid>
  );
}

export default Breadcrumbs;
