import { Typography } from "@mui/material";

const Home = () => {
  return (
    <>
      <Typography>
        About Page
      </Typography>
    </>
  );
}

export default Home;
