import { Box, Card, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { getQotdAnswers } from "../../service/backendService";

const styles = {
  cardStyles: {
    maxWidth: 'fit-content',
    hight: 'fit-content',
    padding: '32px'
  },
  headerHeight: {
    height: 'calc(100vh - 64px)',
  },
  fullHeight: {
    height: '100vh',
  },
  question: {
    marginBottom: '8px'
  },
  answerGrid: {
    marginLeft: '5%',
  },
  answer: {
    marginTop: '24px'
  },
  answerBar: {
    height: '86px',
    backgroundColor: 'lightblue',
  },
  percent: {
    marginLeft: '24px'
  }
}

let className = 'headerHeight'
if (document.location.search.includes('display=true')) className = 'fullHeight';

const today = () => {
  const now = new Date();
  return `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;
}

const divide = (a, b, mult = 1) => {
  return parseFloat(((a/b) * mult).toPrecision(3))
}

const Answer = () => {
  const [questionData, setQuestionData] = useState(null)

  const updateAnswers = async () => {
    const qotdData = await getQotdAnswers(today());
    if (qotdData && qotdData) {
      setQuestionData(qotdData)
    }
    else {
      setQuestionData({ error: true })
    }
  };

  if (questionData === null) {
    updateAnswers();
    return null;
  }
  if (questionData.error) return null
  return (
    <Grid container justifyContent="center" alignItems="center" sx={styles[className]}>
      <Card sx={styles.cardStyles}>
        <Typography variant="h1" sx={styles.question}>
          {questionData.question}
        </Typography>
        {Object.keys(questionData.answers).map((answer) => (
          <Grid key={answer} sx={styles.answerGrid}>
            <Typography variant="h2" sx={styles.answer}>
              {answer}
            </Typography>
            <Grid container direction="row" alignItems="center">
              <Box sx={{ ...styles.answerBar, width: `calc(90% * ${divide(questionData.answers[answer], questionData.responses)} + 5px)` }} />
              <Typography variant="h2" sx={styles.percent}>
                {`${divide(questionData.answers[answer], questionData.responses, 100)}%`}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Card>
    </Grid>
  );
}

export default Answer;
