import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Link from "../common/Link";
import { toggleNavigation, goto } from '../../redux/app';

import MenuBookIcon from '@mui/icons-material/MenuBook';
import CasinoIcon from '@mui/icons-material/Casino';
import InfoIcon from '@mui/icons-material/Info';
import HouseIcon from '@mui/icons-material/House';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

export const navMap = {
  'Home': { path: '/', title: 'ProfDeCube', icon: <HouseIcon /> },
  'About': { path: '/about', title: 'About', icon: <InfoIcon /> },
  'Boardgames': { path: '/boardgames', title: 'Boardgames', icon: <DashboardIcon /> },
  'Dice Roller': { path: '/dice', title: 'Dice Roller', icon: <CasinoIcon /> },
  'Worldbook': { path: '/newworldbook', title: 'Worldbook', icon: <MenuBookIcon /> },
  'GoDice': { path: '/godice', title: 'GoDice', icon: <CasinoIcon /> },
  'discord': { path: '/discord', title: 'Discord Scraper', icon: <QuestionAnswerIcon /> },
};
// 'Poker': { path: '/poker', title: 'Poker', icon: <MenuBookIcon /> },

const Navigation = () => {
  const dispatch = useDispatch();

  const navigationOpen = useSelector(state => state.app.navigationOpen);

  const closeNavigation = () => {
    dispatch(toggleNavigation());
  }

  const visit = (path, title) => {
    dispatch(goto({ path, title }));
    dispatch(toggleNavigation());
  }

  return (
    <Drawer
      anchor="left"
      open={navigationOpen}
      onClose={closeNavigation}
    >
      <List
        sx={{ minWidth: '200px' }}>
        {Object.keys(navMap).map((navItem) => (
          <Link key={`nav-${navItem}`} to={navMap[navItem].path}>
            <ListItem button onClick={() => visit(navMap[navItem].path, navMap[navItem].title)}>
              <ListItemIcon sx={{minWidth: '36px'}}>{navMap[navItem].icon}</ListItemIcon>
              <ListItemText id="switch-list-label-wifi" primary={navItem} />
            </ListItem>
          </Link>
        )
        )}
      </List>
    </Drawer>
  )
}

export default Navigation;