import { Card, Button } from "@mui/material";
import { useEffect, useState } from 'react';

const Webcam = () => {
  const [on, setOn] = useState(false);

  const startStream = () => {
    setOn(true);
  }

  useEffect(() => {
    if (on) {
      const video = document.getElementById("webcamElement");

      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 1920 },
            height: { ideal: 1080 }
          }
        })
          .then((stream) => {
            video.srcObject = stream;
          })
          .catch((err) => {
            console.error("Something went wrong!", err);
          });
      }
    }
  }, [on])

  return (
    <Card elevation={4}>
      {!on && <Button sx={{position: 'absolute', zIndex: 10}} onClick={startStream}>Start Stream</Button>}
      <video autoPlay id="webcamElement" height="450px" width="800px"></video>
    </Card>
  );
}

export default Webcam;
