import { Card, Input, Grid, Divider, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import SuitIcon from './SuitIcon';

const allowedValues = [
  '',
  '1',
  'A',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  'J',
  'Q',
  'K',
];

const suitOrder = [
  'Spades',
  'Hearts',
  'Clubs',
  'Diamonds',
  'Spades',
]

const EditablePlayingCard = ({ getCardDetails, changeListener }) => {
  const [value, setValue] = useState('');
  const [suit, setSuit] = useState('Spades');
  const textColor = (['Hearts', 'Diamonds'].includes(suit) ? 'red' : 'black')
  const textStyle = { color: textColor, width: '24px', fontSize: '18px', fontWeight: '700', paddingTop: '4px', paddingLeft: '4px' }

useEffect(() => {
  setValue('');
  setSuit(suitOrder[0])
}, [changeListener])

  const handleValueChange = (event) => {
    let newValue = event.target.value?.toUpperCase()
    if (allowedValues.includes(newValue)) {
      setValue(newValue)
    }
  }

  getCardDetails(() => ({suit, value}));

  const handleSuitChange = () => {
    const newSuit = suitOrder[suitOrder.indexOf(suit) + 1];
    setSuit(newSuit)
  }

  return (
    <Card sx={{ backgroundColor: 'white', color: textColor, width: '80px', height: '120px' }}>
      <Grid container direction="column">
        <Input
          disableUnderline
          sx={textStyle}
          onChange={handleValueChange}
          inputProps={{ sx: { padding: 0, textAlign: 'center' } }}
          value={value}
        >
          {value}
        </Input>
        <Divider sx={{
          padding: '0, 10px',
          borderColor: textColor,
          width: '22px',
          margin: '-4px 0 0 4px',
        }} />
        <IconButton sx={{ color: 'inherit', padding: 0, marginTop: '4px', width: '60px', alignSelf: 'center' }} onClick={handleSuitChange} ><SuitIcon sx={{ fontSize: '60px', alignSelf: 'center' }} suit={suit} /></IconButton>
        <Input
          disableUnderline
          sx={{ ...textStyle, transform: 'rotate(180deg)', alignSelf: 'end' }}
          inputProps={{ sx: { padding: 0 } }}
          value={value}

        >
          {value}
        </Input>
      </Grid>
    </Card>
  )
}

export default EditablePlayingCard;