import React, { useState } from 'react';
import { Grid, Card, Button, Slider, Box, Typography, Select, MenuItem, TextField, InputAdornment } from "@mui/material";
import GoDie from '../../utils/goDice';
import BatteryIcon from '@mui/icons-material/Battery50';

const Dice = () => {
  const [dice, setDice] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [rand, setRand] = useState(0);
  const [r, setR] = useState(255);
  const [g, setG] = useState(255);
  const [b, setB] = useState(255);
  const [dropKeep, setDropKeep] = useState(0);
  const [dropOrKeep, setDropOrKeep] = useState("DROP");
  const [mod, setMod] = useState(0);
  GoDie.prototype.onDiceConnected = (diceId, diceInstance) => {
    // Called when a new die is connected - create a dedicate panel for this die
    const newDice = { ...dice }
    newDice[diceId] = diceInstance;
    setDice(newDice);
    window.connectedDice = newDice;
    diceInstance.getDiceColor();
  };

  GoDie.prototype.onDiceDisconnected = (thisD) => {
    // Called when a new die is connected - create a dedicate panel for this die
    const newDice = { ...dice }
    clearTimeout(thisD.clocking);
    delete newDice[thisD.GlobalDeviceId];
    setDice(newDice);
    window.connectedDice = newDice;
  };

  GoDie.prototype.onOrientationChange = (a, b, c) => {
    setRand(Math.random());
  }
  GoDie.prototype.onBatteryLevel = (thisD) => {
    setRand(Math.random());
    if (!thisD.clocking) {
      thisD.clocking = setTimeout(() => {
        thisD.getBatteryLevel();
      }, 2 * 60 * 1000);
    }
  }
  GoDie.prototype.onRollStart = (thisD) => {
    thisD.rolledValue = 0;
    setRand(Math.random());
  }
  GoDie.prototype.onDiceColor = (thisD, colorInt) => {
    thisD.color = Object.keys(thisD.diceColour)[colorInt];
    thisD.getBatteryLevel();
  }

  const addDie = () => {
    const newDie = new GoDie();
    newDie.requestDevice();
  }

  const lightDice = (die) => {
    die.setLed([r, g, b], [r, g, b]);
    setTimeout(() => {
      die.setLed([0], [0]);
    }, 2 * 1000);
  }

  const updateRGBValue = (event, setterFunction) => {
    let value = event.target.value + '';
    value = value.replace(/[^0-9]+/g, '');
    value = value.replace(/^0+/, '');
    if (!value.length) value = 0
    if (value < 256) setterFunction(parseInt(value))
  }
  let total = (mod === '-') ? 0 : mod;
  const boxColour = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`

  const setDropKeepValid = (event) => {
    let value = event.target.value + '';
    value = value.replace(/[^0-9]+/g, '');
    value = value.replace(/^0+/, '');
    if (!value.length) value = 0
    if (value < 6) setDropKeep(parseInt(value));
  }

  const setDropOrKeepValue = (event) => {
    setDropOrKeep(event.target.value)
  }


  const allDice = Object.values(dice);
  if (dropKeep > allDice.length) setDropKeep(allDice.length);
  allDice.sort((a, b) => b.rolledValue - a.rolledValue);

  const keptDice = allDice.map(die => die.GlobalDeviceId);
  const startSplice = dropOrKeep === "DROP" ? allDice.length - dropKeep : dropKeep;
  const deleteCount = dropOrKeep === "DROP" ? dropKeep : allDice.length - dropKeep;
  keptDice.splice(startSplice, deleteCount);

  const updateMod = (event) => {
    let value = event.target.value.replace("+", "");
    value = value.replace(/[^-0-9]+/g, '');
    value = value.replace(/^0+/, '');
    value = value.replace(/-+/, '-');
    if (!value.length) value = 0
    if (value !== '-') value = parseInt(value)
    setMod(value)
  }

  const setDieShell = (event, diceId) => {
    window.connectedDice[diceId].setShell(event.target.value);
    setRand(Math.random())
  }

  return (
    <Grid sx={{ padding: '16px' }}>
      <Grid>
        <Grid>
          <Grid container flexWrap="nowrap" alignItems="center">
            <Slider min={0} max={255} value={r} onChange={(e) => updateRGBValue(e, setR)}></Slider>
            <Typography sx={{ whiteSpace: 'nowrap', marginLeft: '16px', minWidth: '80px' }}>
              {`Red: ${r}`}
            </Typography>
          </Grid>

          <Grid container flexWrap="nowrap" alignItems="center">
            <Slider min={0} max={255} value={g} onChange={(e) => updateRGBValue(e, setG)}></Slider>
            <Typography sx={{ whiteSpace: 'nowrap', marginLeft: '16px', minWidth: '80px' }}>
              {`Green: ${g}`}
            </Typography>
          </Grid>

          <Grid container flexWrap="nowrap" alignItems="center">
            <Slider min={0} max={255} value={b} onChange={(e) => updateRGBValue(e, setB)}></Slider>
            <Typography sx={{ whiteSpace: 'nowrap', marginLeft: '16px', minWidth: '80px' }}>
              {`Blue: ${b}`}
            </Typography>
          </Grid>

          <Box sx={{ backgroundColor: boxColour, height: '15px', width: '300px', margin: '8px 0' }}></Box>
        </Grid>
        <Grid container justifyContent="center" sx={{ marginBottom: '12px', gap: '12px' }}>
          <Select value={dropOrKeep} onChange={setDropOrKeepValue}>
            <MenuItem value="DROP">Drop</MenuItem>
            <MenuItem value="KEEP">Keep</MenuItem>
          </Select>
          <Select value={dropKeep} onChange={setDropKeepValid}>
            {[...Array(allDice.length + 1).keys()].map(i => <MenuItem key={i} value={i}>{i}</MenuItem>)}
          </Select>
          <TextField
            onChange={updateMod}
            InputProps={{
              startAdornment: <InputAdornment position="start">MOD</InputAdornment>,
            }} value={mod < 0 ? mod : `+${mod}`}></TextField>
        </Grid>
        {Object.values(dice).map((die) => {
          if (die.rolledValue && keptDice.includes(die.GlobalDeviceId)) total += die.rolledValue;
          const bgColour = (keptDice.includes(die.GlobalDeviceId)) ? 'white' : 'gray';
          return (
            <Grid key={die.GlobalDeviceId} container alignItems="center"
              flexWrap="nowrap" justifyContent="space-between" sx={{ margin: '12px' }}>
              <Grid container alignItems="center" sx={{ width: 'initial' }}>
                <Card
                  onClick={() => lightDice(die)}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: bgColour,
                    color: die.color || 'white',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '36px',
                    fontWeight: 'bold',
                    textShadow: '0 0 1px black',
                  }}
                  className="die"
                >
                  {die.rolledValue}
                </Card>
                <BatteryIcon />
                {`: ${die.batteryLevel}%`}
              </Grid>
              <Grid>
                <Select value={die.currentShell} onChange={(e) => setDieShell(e, die.GlobalDeviceId)}>
                  <MenuItem disabled value="D4">D4</MenuItem>
                  <MenuItem value="NONE">D6</MenuItem>
                  <MenuItem disabled value="D8">D8</MenuItem>
                  <MenuItem value="D10">D10</MenuItem>
                  <MenuItem disabled value="D12">D12</MenuItem>
                  <MenuItem value="D20">D20</MenuItem>
                  <MenuItem value="DPERCENT">D%</MenuItem>
                </Select>
              </Grid>
              <Button color="error" onClick={() => die.onDisconnectButtonClick()}>X</Button>
            </Grid>
          )
        })}
      </Grid>
      {Object.values(dice).length > 0 && <Typography sx={{ marginLeft: '12px', marginBottom: '12px' }}>{`Total: ${total}`}</Typography>}
      <Grid container justifyContent="center">
        <Button onClick={addDie} variant="outlined">Add Die</Button>
      </Grid>
    </Grid>
  );
}

export default Dice;
