import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  theme: 'dark',
  pageTitle: 'ProfDeCube',
  navigationOpen: false,
  // eslint-disable-next-line no-restricted-globals
  path: location.pathname,
  user: null,
  allGames: { games: [], expansions: [] },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    setPageTitle: (state, action) => {
      //state.pageTitle = action.payload
      const title = (action.payload === 'ProfDeCube') ? `ProfDeCube` : `ProfDeCube - ${action.payload}`
      document.title = title;
    },
    toggleNavigation: (state) => {
      state.navigationOpen = !state.navigationOpen;
    },
    goto: (state, action) => {
      state.path = action.payload.path;
      if (action.payload.title) state.pageTitle = action.payload.title
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setAllGames: (state, action) => {
      state.allGames = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTheme, setPageTitle, goto, toggleNavigation, setUser, setAllGames } = appSlice.actions

export default appSlice.reducer