import React from 'react';
import { Grid, Card, Button } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DiceOption from './DiceOption';
import { randomInt } from "../../utils/random";
import { useSelector, useDispatch } from "react-redux";
import { addRolls, resetRolls } from "../../redux/diceRoller";
import { setPageTitle } from '../../redux/app';
import DiceRow from './DiceRow';

const DiceRoller = () => {
  const { maxRolls, dice, count, rolls, set, seed } = useSelector(state => state.dice);
  const dispatch = useDispatch();
  dispatch(setPageTitle('Dice Roller'));

  const doRoll = () => {
    const rolled = []
    for (let i = 0; i < count; i++) {
      rolled.push(randomInt(1, 6))
    }

    dispatch(addRolls(rolled));
  }

  const reset = () => {
    dispatch(resetRolls())
  }

  const share = () => {
    navigator.clipboard.writeText(
      `https://ProfDeCube.com/dice?dice=${dice}&count=${count}&rolls=${maxRolls}&seed=${seed}`
      )
  }


  return (
    <Grid container justifyContent="center">
      <Card sx={{margin: '8px', padding: '8px'}}>
        <Grid container alignItems="center" direction="column">
          <DiceOption />
          <DiceRow />
          {set &&
            <Grid container sx={{ width: '100%' }}>
              <Button sx={{ width: '36px', minWidth: '36px' }} onClick={reset} color="error"><RestartAltIcon /></Button>
              <Button sx={{ width: 'calc(100% - 72px)' }} onClick={doRoll} disabled={maxRolls && rolls.length >= maxRolls}>Roll!</Button>
              <Button sx={{ width: '36px', minWidth: '36px' }} onClick={share}><ShareIcon /></Button>
            </Grid>
          }
        </Grid>

      </Card>
    </Grid>
  );
}

export default DiceRoller;
