import PropTypes from 'prop-types'
import { Typography, Grid, Card } from "@mui/material";

const DieFace = ({ die, face, dimmed }) => {
  const styles = { width: '40px', height: '40px', margin: '8px' }
  const dimmedStyles = { ...styles, opacity: '40%', boxShadow: 'none' }
  return (
    <Card elevation={4} sx={dimmed ? dimmedStyles : styles}>
      <Grid container alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <Typography>{face}</Typography>
      </Grid>
    </Card>
  );
}

DieFace.propTypes = {
  die: PropTypes.string.isRequired,
  face: PropTypes.number.isRequired,
  dimmed: PropTypes.bool,
}

DieFace.defaultProps = {
  dimmed: false,
}

export default DieFace;
