import React from 'react';
import { Grid, Divider, Typography } from "@mui/material";
import DieFace from './DieFace';
import { useSelector } from "react-redux";

const DiceRow = () => {
  const { dice, rolls, dropKeep, showTotal } = useSelector(state => state.dice);

  return (
    <>
      {rolls &&
        rolls.map((rollRow, rowIndex) => {
          const indeciesOfDimmed = []
          const rollRowDestuctive = [...rollRow];
          if (dropKeep) {
            let sortedRolls
            if (dropKeep.substr(0, 1) === 'd') {
              sortedRolls = [...rollRow].sort();
            } else {
              sortedRolls = [...rollRow].sort().reverse();
            }
            const dropKeepCount = parseInt(dropKeep.substr(1), 10);
            for (let i = 0; i < dropKeepCount; i++) {
              const rollValue = sortedRolls.shift();
              const rollValueIndex = rollRowDestuctive.indexOf(rollValue)
              indeciesOfDimmed.push(rollValueIndex);
              rollRowDestuctive[rollValueIndex] = Infinity;
            }
          }
          const total = rollRowDestuctive.filter((i) => i !== Infinity).reduce((a, b) => a + b, 0);
          return (
            <React.Fragment key={`row-${rowIndex}`}>
              <Grid container justifyContent="center" alignItems="center" direction="row">
                {
                  rollRow.map((roll, diceIndex) => (
                    <DieFace face={roll} die={dice} dimmed={indeciesOfDimmed.includes(diceIndex)} key={`die-${rowIndex}-${diceIndex}`} />
                  ))
                }
                {showTotal && <Typography sx={{ margin: '8px', width: '18px', textAlign: 'center' }}>{total}</Typography>}
              </Grid>
              <Divider sx={{ width: '100%' }} />
            </React.Fragment>
          )
        })
      }
    </>
  );
}

export default DiceRow;
