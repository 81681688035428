import { Provider } from 'react-redux';

import reduxStore from '../../redux';
import Auth from './Auth';


const ReduxProvider = () => {
  return (
    <Provider store={reduxStore}>
      <Auth />
    </Provider>
  );
}

export default ReduxProvider;
