import { SvgIcon } from "@mui/material"

const CardBack = ({backColour}) => {
  return (
    <SvgIcon viewBox="0 12 170 220" sx={{fontSize: '120px', width: '0.69em', fill: backColour}}>
      <path style={{ fill: '#ffffff', strokeWidth: 0.49925327 }} d="M 166.82959,235.5459 C 166.82959,239.32031 163.74365,242.41504 159.95947,242.41504 L 7.1108398,242.41504 C 3.3359375,242.41504 0.25,239.32031 0.25,235.5459 L 0.25,7.1201172 C 0.25,3.3408203 3.3359375,0.25 7.1108398,0.25 L 159.95947,0.25 C 163.74365,0.25 166.82959,3.3408203 166.82959,7.1201172 L 166.82959,235.5459 L 166.82959,235.5459 z " id="path5" />
      <path style={{ fillRule: 'evenodd', stroke: '#fb0f0c', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 27.634766,32.611816 C 62.116699,32.611816 96.599121,32.611816 131.08057,32.611816 C 131.89307,32.611816 139.7085,32.239746 139.7085,32.905762 C 139.7085,36.643066 139.7085,40.379883 139.7085,44.117188 C 139.7085,62.668457 139.7085,81.219727 139.7085,99.770996 C 139.7085,135.77051 139.7085,171.77051 139.7085,207.77051 C 139.7085,208.02637 139.88232,209.19434 139.56592,209.19434 C 138.02002,209.19434 136.4751,209.19434 134.93018,209.19434 C 126.90576,209.19434 118.88232,209.19434 110.85889,209.19434 C 89.085449,209.19434 67.312988,209.19434 45.540039,209.19434 C 39.797851,209.19434 34.055664,209.19434 28.313965,209.19434 C 26.852539,209.19434 27.634766,205.13184 27.634766,203.96973 C 27.634766,187.7666 27.634766,171.56348 27.634766,155.36035 C 27.634766,116.27246 27.634766,77.185059 27.634766,38.097168 C 27.634766,36.268555 27.634766,34.44043 27.634766,32.611816" id="path7" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 61.656738,96.088867 C 66.12793,96.088867 105.68506,95.774414 105.68506,96.399414 C 105.68506,113.15625 105.68506,129.91309 105.68506,146.6709 C 105.68506,147.1748 61.656738,146.73145 61.656738,146.8584 C 61.656738,130.19531 61.656738,113.53223 61.656738,96.869141 C 61.656738,96.608887 61.656738,96.348633 61.656738,96.088867" id="path9" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 83.671387,91.472168 C 79.335449,96.473633 74.999023,101.47461 70.662598,106.47607 C 67.73877,109.84863 64.814941,113.2207 61.890625,116.59326 C 61.211914,117.37598 57.653808,120.39209 57.653808,121.47949 C 57.653808,122.47949 60.927246,125.25488 61.550293,125.97363 C 64.587891,129.47754 67.625488,132.97949 70.662598,136.4834 C 74.999023,141.48438 79.335449,146.48535 83.671387,151.4873 C 88.007324,146.48535 92.343262,141.48438 96.680176,136.4834 C 99.604004,133.11035 102.52783,129.73828 105.45166,126.36621 C 106.13037,125.58301 109.68896,122.56738 109.68896,121.47949 C 109.68896,120.47949 106.41553,117.70459 105.79248,116.98584 C 102.75537,113.48242 99.717285,109.97949 96.680176,106.47607 C 92.343262,101.47461 88.007324,96.473633 83.671387,91.472168" id="path11" />
      <path style={{ fillRule: 'evenodd', stroke: '#fb0f0c', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 63.658203,98.396973 C 71.943848,98.396973 80.229492,98.396973 88.515137,98.396973 C 92.972168,98.396973 97.429199,98.396973 101.88721,98.396973 C 104.19287,98.396973 103.68408,98.593262 103.68408,100.90576 C 103.68408,111.04785 103.68408,121.18945 103.68408,131.33203 C 103.68408,135.30273 103.68408,139.27344 103.68408,143.24414 C 103.68408,145.34277 102.46631,144.5625 100.45654,144.5625 C 91.718262,144.5625 82.980469,144.5625 74.242676,144.5625 C 71.039551,144.5625 67.835937,144.5625 64.632812,144.5625 C 63.055664,144.5625 63.658203,143.54199 63.658203,142.05371 C 63.658203,127.50098 63.658203,112.94922 63.658203,98.396973" id="path13" />
      <path style={{ fillRule: 'evenodd', stroke: '#fb0f0c', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 83.671387,93.780762 C 79.744629,98.30957 75.818359,102.83838 71.891602,107.36719 C 69.092285,110.5957 66.292969,113.82422 63.493652,117.05273 C 62.879395,117.76172 59.655273,120.49463 59.655273,121.47949 C 59.655273,122.37793 62.595215,124.87012 63.154785,125.51563 C 65.915039,128.69922 68.675293,131.88281 71.435547,135.06641 C 75.51416,139.77051 79.592773,144.47461 83.671387,149.17871 C 87.598144,144.64941 91.524902,140.12109 95.450684,135.59277 C 98.250488,132.36426 101.05029,129.13574 103.84912,125.90723 C 104.46338,125.19824 107.68701,122.46484 107.68701,121.47949 C 107.68701,120.58154 104.74756,118.08887 104.18799,117.44385 C 101.42725,114.26025 98.66748,111.07666 95.907715,107.89307 C 91.828613,103.18896 87.749512,98.484863 83.671387,93.780762" id="path15" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 67.660645,103.01367 C 76.352051,103.01367 85.043457,103.01367 93.734863,103.01367 C 94.819824,103.01367 99.681152,102.28076 99.681152,103.60547 C 99.681152,107.52148 99.681152,111.43701 99.681152,115.35254 C 99.681152,117.0752 99.702637,139.94629 99.661621,139.94629 C 90.794434,139.94629 81.928223,139.94629 73.061035,139.94629 C 72.002441,139.94629 67.660645,140.66895 67.660645,139.35352 C 67.660645,135.43848 67.660645,131.52246 67.660645,127.60645 C 67.660645,119.40918 67.660645,111.21143 67.660645,103.01367" id="path17" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 83.671387,98.396973 C 80.335938,102.24414 77.000488,106.09131 73.665039,109.93848 C 71.429199,112.51709 69.193359,115.0957 66.95752,117.67432 C 66.429199,118.28369 63.658203,120.63281 63.658203,121.47949 C 63.658203,122.23926 66.143555,124.34668 66.616211,124.8916 C 68.96582,127.60156 71.31543,130.31152 73.665039,133.02051 C 77.000488,136.86816 80.335937,140.71582 83.671387,144.5625 C 87.007324,140.71582 90.342285,136.86816 93.677246,133.02051 C 95.913574,130.44238 98.149902,127.86426 100.38428,125.28516 C 100.91357,124.67578 103.68408,122.32617 103.68408,121.47949 C 103.68408,120.7207 101.19873,118.61328 100.72607,118.06787 C 98.376465,115.35791 96.026855,112.64844 93.677246,109.93848 C 90.342285,106.09131 87.007324,102.24414 83.671387,98.396973" id="path19" />
      <path style={{ fillRule: 'evenodd', stroke: '#fb0f0c', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 69.662109,105.32178 C 78.199707,105.32178 86.737793,105.32178 95.274902,105.32178 C 98.35791,105.32178 97.680176,105.59473 97.680176,108.68262 C 97.680176,113.86768 97.680176,119.05273 97.680176,124.2373 C 97.680176,128.48047 97.680176,132.72363 97.680176,136.96582 C 97.680176,138.45801 92.440918,137.6377 91.218262,137.6377 C 85.805176,137.6377 80.391113,137.6377 74.977539,137.6377 C 73.088867,137.6377 69.662109,138.74023 69.662109,136.46777 C 69.662109,126.08594 69.662109,115.7041 69.662109,105.32178" id="path21" />
      <path style={{ fillRule: 'evenodd', stroke: '#fb0f0c', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 83.671387,100.70508 C 80.746094,104.07959 77.820313,107.4541 74.894531,110.82861 C 72.783203,113.26367 70.671875,115.69922 68.560058,118.13428 C 68.096191,118.66943 65.659668,120.73535 65.659668,121.47949 C 65.659668,122.13672 67.812012,123.96191 68.220703,124.43359 C 70.292969,126.82324 72.364746,129.21289 74.436523,131.60254 C 77.515137,135.15332 80.593262,138.7041 83.671387,142.25391 C 86.597168,138.87988 89.522949,135.50488 92.448731,132.13086 C 94.560059,129.69629 96.671387,127.26074 98.782715,124.8252 C 99.246582,124.29004 101.68311,122.22363 101.68311,121.47949 C 101.68311,120.82324 99.530762,118.99707 99.122559,118.52588 C 97.050293,116.13574 94.978027,113.74609 92.905762,111.35645 C 89.827637,107.80615 86.749512,104.25586 83.671387,100.70508" id="path23" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 83.671387,103.01367 C 81.078613,106.00439 78.48584,108.99512 75.892578,111.98535 C 74.069336,114.08887 72.245605,116.19189 70.421875,118.29492 C 69.97998,118.80469 67.660645,120.77148 67.660645,121.47949 C 67.660645,122.09961 69.691895,123.82227 70.078125,124.26758 C 71.865234,126.3291 73.652344,128.38965 75.439453,130.45215 C 78.183594,133.61621 80.927734,136.78125 83.671387,139.94629 C 86.26416,136.95508 88.856934,133.96484 91.450684,130.97363 C 93.272949,128.87109 95.097168,126.76758 96.92041,124.66504 C 97.362793,124.1543 99.681152,122.18848 99.681152,121.47949 C 99.681152,120.86035 97.649902,119.13672 97.265137,118.69189 C 95.478027,116.63037 93.689942,114.56934 91.902832,112.50781 C 89.159668,109.34326 86.415527,106.17871 83.671387,103.01367" id="path25" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 71.663086,107.62988 C 79.633789,107.62988 87.60498,107.62988 95.575684,107.62988 C 95.784668,107.62988 95.679199,130.28711 95.679199,132.44824 C 95.679199,132.88574 95.991699,134.9873 95.679199,135.3291 C 95.32959,135.71094 92.419434,135.3291 91.927246,135.3291 C 86.601074,135.3291 81.274414,135.3291 75.948242,135.3291 C 74.554688,135.3291 73.160644,135.3291 71.76709,135.3291 C 71.533203,135.3291 71.663086,133.13379 71.663086,132.87793 C 71.663086,127.71191 71.663086,122.5459 71.663086,117.38037 C 71.663086,114.13037 71.663086,110.87988 71.663086,107.62988" id="path27" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 83.671387,107.62988 C 81.669922,109.93848 79.668945,112.24658 77.66748,114.55469 C 76.291504,116.1416 74.915527,117.72852 73.539551,119.31592 C 73.241211,119.66016 71.663086,121 71.663086,121.47949 C 71.663086,121.95996 73.241211,123.2998 73.539551,123.64355 C 74.915527,125.23047 76.291504,126.81738 77.66748,128.4043 C 79.668945,130.71289 81.669922,133.02051 83.671387,135.3291 C 85.67334,133.02051 87.67334,130.71289 89.675293,128.4043 C 91.05127,126.81738 92.427246,125.23047 93.802246,123.64355 C 94.101074,123.2998 95.679199,121.95996 95.679199,121.47949 C 95.679199,121 94.101074,119.66016 93.802246,119.31592 C 92.427246,117.72852 91.051269,116.1416 89.675293,114.55469 C 87.67334,112.24658 85.67334,109.93848 83.671387,107.62988" id="path29" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 113.69092,93.780762 C 112.35693,93.780762 111.02295,93.780762 109.68896,93.780762 C 111.02295,95.319336 112.35693,96.858398 113.69092,98.396973 C 113.69092,96.858398 113.69092,95.319336 113.69092,93.780762" id="path31" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 113.69092,100.70508 C 112.35693,99.166504 111.02295,97.627441 109.68896,96.088867 C 109.68896,97.584473 109.68896,99.080078 109.68896,100.57617 C 109.68896,100.84717 113.17529,100.70508 113.69092,100.70508" id="path33" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 53.651856,93.780762 C 54.98584,93.780762 56.319824,93.780762 57.653809,93.780762 C 56.319824,95.319336 54.98584,96.858398 53.651856,98.396973 C 53.651856,96.858398 53.651856,95.319336 53.651856,93.780762" id="path35" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 53.651856,100.70508 C 54.98584,99.166504 56.319824,97.627441 57.653809,96.088867 C 57.653809,97.584473 57.653809,99.080078 57.653809,100.57617 C 57.653809,100.84717 54.167969,100.70508 53.651856,100.70508" id="path37" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 57.653809,142.25391 C 56.319824,142.25391 54.98584,142.25391 53.651855,142.25391 C 54.98584,143.79297 56.319824,145.33203 57.653809,146.87012 C 57.653809,145.33203 57.653809,143.79297 57.653809,142.25391" id="path39" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 57.653809,149.17871 C 56.319824,147.63965 54.98584,146.10059 53.651855,144.5625 C 53.651855,146.05762 53.651855,147.55371 53.651855,149.0498 C 53.651855,149.32129 57.138184,149.17871 57.653809,149.17871" id="path41" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 109.68896,142.25391 C 111.02295,142.25391 112.35693,142.25391 113.69092,142.25391 C 112.35693,143.79297 111.02295,145.33203 109.68896,146.87012 C 109.68896,145.33203 109.68896,143.79297 109.68896,142.25391" id="path43" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 109.68896,149.17871 C 111.02295,147.63965 112.35693,146.10059 113.69092,144.5625 C 113.69092,146.05762 113.69092,147.55371 113.69092,149.0498 C 113.69092,149.32129 110.20459,149.17871 109.68896,149.17871" id="path45" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 57.653809,126.09668 C 56.319824,126.09668 54.98584,126.09668 53.651855,126.09668 C 54.98584,127.63574 56.319824,129.17383 57.653809,130.71289 C 57.653809,129.17383 57.653809,127.63574 57.653809,126.09668" id="path47" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 57.653809,133.02051 C 56.319824,131.48242 54.98584,129.94336 53.651855,128.4043 C 53.651855,129.90039 53.651855,131.39551 53.651855,132.8916 C 53.651855,133.16309 57.138184,133.02051 57.653809,133.02051" id="path49" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 53.651856,109.93848 C 54.98584,109.93848 56.319824,109.93848 57.653809,109.93848 C 56.319824,111.47705 54.98584,113.01611 53.651856,114.55469 C 53.651856,113.01611 53.651856,111.47705 53.651856,109.93848" id="path51" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 53.651856,116.86328 C 54.98584,115.32422 56.319824,113.78564 57.653809,112.24658 C 57.653809,113.74219 57.653809,115.23828 57.653809,116.73389 C 57.653809,117.00488 54.167969,116.86328 53.651856,116.86328" id="path53" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 113.69092,109.93848 C 112.35693,109.93848 111.02295,109.93848 109.68896,109.93848 C 111.02295,111.47705 112.35693,113.01611 113.69092,114.55469 C 113.69092,113.01611 113.69092,111.47705 113.69092,109.93848" id="path55" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 113.69092,116.86328 C 112.35693,115.32422 111.02295,113.78564 109.68896,112.24658 C 109.68896,113.74219 109.68896,115.23828 109.68896,116.73389 C 109.68896,117.00488 113.17529,116.86328 113.69092,116.86328" id="path57" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 109.68896,126.09668 C 111.02295,126.09668 112.35693,126.09668 113.69092,126.09668 C 112.35693,127.63574 111.02295,129.17383 109.68896,130.71289 C 109.68896,129.17383 109.68896,127.63574 109.68896,126.09668" id="path59" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 109.68896,133.02051 C 111.02295,131.48242 112.35693,129.94336 113.69092,128.4043 C 113.69092,129.90039 113.69092,131.39551 113.69092,132.8916 C 113.69092,133.16309 110.20459,133.02051 109.68896,133.02051" id="path61" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 69.711914,24.821289 C 68.894043,22.796875 68.028809,20.734863 67.467774,18.619629 C 66.961914,16.713379 66.559082,12.05957 69.095215,11.144531 C 71.52832,10.267578 72.412598,14.474121 72.380859,16.126953 C 72.333008,18.609863 71.152832,21.146484 70.26416,23.428711 C 70.083496,23.893555 69.898926,24.359863 69.711914,24.821289" id="path63" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 69.699219,24.867188 C 67.687988,24.020508 65.44873,23.190918 63.668457,21.905273 C 62.088379,20.763672 59.571777,17.380371 61.040039,15.311035 C 62.521973,13.222168 65.614258,15.616211 66.613281,17.027344 C 68.046387,19.050293 68.719727,21.817383 69.472168,24.151855 C 69.548828,24.389648 69.624023,24.630371 69.699219,24.867187" id="path65" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 69.705566,24.867188 C 71.716797,24.02002 73.955566,23.190918 75.736328,21.905273 C 77.316406,20.76416 79.832519,17.379883 78.364746,15.311035 C 76.882812,13.22168 73.790039,15.616211 72.791016,17.027344 C 71.330078,19.090332 70.645996,21.926758 69.881836,24.30957 C 69.822754,24.494629 69.763672,24.683106 69.705566,24.867188" id="path67" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 64.552246,25.144531 C 62.924805,24.661621 60.90918,24.821289 59.869141,26.329102 C 59.314941,27.132813 59.188965,30.24707 60.953125,29.641602 C 61.260742,29.536133 61.512207,28.170899 61.887207,27.856445 C 62.602051,27.256836 63.940918,27.275391 64.809082,27.206055 C 65.977051,27.11377 67.189941,27.238281 68.337891,27.07959 C 68.339355,27.072754 68.341309,27.066406 68.344727,27.060059 C 67.256836,26.217285 65.870117,25.552246 64.552246,25.144531" id="path69" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 74.772461,25.144531 C 76.399902,24.661133 78.415527,24.821289 79.455078,26.329102 C 80.009277,27.132813 80.135742,30.247559 78.371094,29.641602 C 78.063477,29.536133 77.8125,28.171387 77.4375,27.856445 C 76.723145,27.256836 75.383789,27.275391 74.515625,27.206055 C 73.347168,27.11377 72.134766,27.238281 70.986328,27.07959 C 70.984863,27.072754 70.98291,27.066406 70.979492,27.060059 C 72.067871,26.217285 73.454102,25.552246 74.772461,25.144531" id="path71" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 68.328125,29.629883 C 68.565918,31.663574 72.092285,30.674805 70.405762,27.875977 C 69.221191,25.90918 68.54248,27.749512 68.328125,29.629883" id="path73" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 97.72998,24.821289 C 96.911621,22.797363 96.046387,20.734375 95.48584,18.619629 C 94.97998,16.713379 94.577637,12.060059 97.112793,11.144531 C 99.546387,10.266602 100.43115,14.474609 100.39893,16.126953 C 100.35107,18.610352 99.171387,21.146484 98.282715,23.428711 C 98.101074,23.893555 97.91748,24.359375 97.72998,24.821289" id="path75" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 97.717285,24.867188 C 95.706543,24.020508 93.466309,23.190918 91.687012,21.905273 C 90.106934,20.763672 87.590332,17.379883 89.058106,15.311035 C 90.540527,13.222656 93.632324,15.615723 94.632324,17.027344 C 96.064942,19.050293 96.737793,21.817871 97.489746,24.151855 C 97.566895,24.389648 97.64209,24.630371 97.717285,24.867187" id="path77" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 97.724121,24.867188 C 99.734863,24.02002 101.97412,23.190918 103.75439,21.905273 C 105.33545,20.763672 107.85107,17.380371 106.38232,15.311035 C 104.90088,13.222168 101.80811,15.616211 100.80908,17.027344 C 99.347168,19.090332 98.665527,21.926758 97.900879,24.30957 C 97.841309,24.495117 97.782715,24.682617 97.724121,24.867188" id="path79" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 92.569824,25.144531 C 90.942871,24.661133 88.927246,24.821777 87.888184,26.329102 C 87.333496,27.133301 87.206543,30.247559 88.972168,29.641602 C 89.278809,29.536133 89.530762,28.170899 89.905762,27.856445 C 90.619629,27.256836 91.959473,27.275391 92.827637,27.206055 C 93.995606,27.11377 95.208496,27.238281 96.355957,27.07959 C 96.356934,27.072754 96.359863,27.066406 96.362793,27.060059 C 95.273926,26.217285 93.88916,25.552246 92.569824,25.144531" id="path81" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 102.79053,25.144531 C 104.41748,24.661133 106.43408,24.821777 107.47412,26.329102 C 108.02783,27.132813 108.15381,30.24707 106.39014,29.641602 C 106.08154,29.536133 105.83057,28.170899 105.45557,27.856445 C 104.7417,27.256836 103.40186,27.275391 102.53369,27.206055 C 101.36572,27.113281 100.15283,27.238281 99.004394,27.07959 C 99.003418,27.072754 99.001465,27.066406 98.997559,27.060059 C 100.08643,26.217773 101.47217,25.552246 102.79053,25.144531" id="path83" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 96.346191,29.629883 C 96.584473,31.663574 100.11084,30.674805 98.424316,27.875977 C 97.239746,25.908691 96.560059,27.75 96.346191,29.629883" id="path85" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 125.74854,24.821289 C 124.93115,22.796387 124.06494,20.734863 123.50342,18.619629 C 122.99854,16.713379 122.59521,12.05957 125.13232,11.144531 C 127.56494,10.26709 128.44873,14.474609 128.41748,16.126953 C 128.36865,18.610352 127.18897,21.145996 126.30029,23.428711 C 126.12061,23.893555 125.93506,24.359863 125.74854,24.821289" id="path87" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 125.73584,24.867188 C 123.72412,24.02002 121.48584,23.190918 119.70459,21.905273 C 118.12451,20.763672 115.60889,17.379883 117.07666,15.311035 C 118.55811,13.222168 121.65088,15.616211 122.6499,17.027344 C 124.11084,19.090332 124.79443,21.92627 125.55811,24.30957 C 125.61865,24.495117 125.67725,24.682617 125.73584,24.867188" id="path89" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 125.7417,24.867188 C 127.75342,24.020508 129.99268,23.190918 131.77295,21.905273 C 133.35303,20.763672 135.86865,17.379883 134.40186,15.311035 C 132.91943,13.222168 129.82666,15.616211 128.82764,17.027344 C 127.3667,19.090332 126.68311,21.926758 125.91943,24.30957 C 125.85986,24.494629 125.80029,24.683106 125.7417,24.867188" id="path91" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 120.58936,25.144531 C 118.96143,24.661133 116.9458,24.821777 115.90576,26.329102 C 115.35107,27.133301 115.2251,30.24707 116.98975,29.641602 C 117.29736,29.536133 117.54834,28.170899 117.92334,27.856445 C 118.63818,27.256836 119.97803,27.275391 120.84521,27.206055 C 122.01416,27.11377 123.22607,27.238281 124.37451,27.07959 C 124.37646,27.072754 124.37842,27.066406 124.38135,27.060059 C 123.29248,26.217285 121.90771,25.552246 120.58936,25.144531" id="path93" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 130.80811,25.144531 C 132.43701,24.661621 134.45264,24.821289 135.4917,26.329102 C 136.04639,27.132813 136.17334,30.246582 134.40771,29.641602 C 134.10107,29.536133 133.84912,28.171387 133.47412,27.856445 C 132.75928,27.256836 131.42041,27.275391 130.55225,27.206055 C 129.38428,27.11377 128.17139,27.238281 127.02295,27.07959 C 127.021,27.072754 127.01904,27.066406 127.01709,27.060059 C 128.10498,26.217285 129.49072,25.552246 130.80811,25.144531" id="path95" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 124.36475,29.629883 C 124.60303,31.664551 128.12842,30.673828 126.44287,27.875977 C 125.25732,25.908691 124.57959,27.75 124.36475,29.629883" id="path97" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 41.693848,24.821289 C 40.875488,22.797363 40.010254,20.734375 39.449219,18.619629 C 38.943359,16.713379 38.541016,12.05957 41.07666,11.144531 C 43.510254,10.266602 44.394043,14.474609 44.362305,16.126953 C 44.314453,18.610352 43.134277,21.145996 42.245605,23.428711 C 42.064941,23.893555 41.880371,24.359375 41.693848,24.821289" id="path99" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 41.680664,24.867188 C 39.668945,24.02002 37.430664,23.190918 35.649902,21.905273 C 34.069824,20.763672 31.553711,17.380371 33.021484,15.311035 C 34.503418,13.222168 37.596191,15.616211 38.595215,17.027344 C 40.027832,19.050293 40.70166,21.817871 41.453613,24.151855 C 41.530274,24.389648 41.605469,24.630371 41.680664,24.867187" id="path101" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 41.687012,24.867188 C 43.698242,24.020508 45.9375,23.190918 47.717773,21.905273 C 49.297852,20.763672 51.814453,17.380371 50.346191,15.311035 C 48.863769,13.222168 45.771973,15.616211 44.772461,17.027344 C 43.339844,19.050293 42.666504,21.817871 41.914063,24.151855 C 41.837402,24.389648 41.762207,24.629883 41.687012,24.867187" id="path103" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 36.533691,25.144531 C 34.90625,24.661133 32.890625,24.821777 31.851074,26.329102 C 31.296875,27.132813 31.17041,30.247559 32.935059,29.641602 C 33.242188,29.536133 33.493652,28.170899 33.868652,27.856445 C 34.583008,27.256836 35.922363,27.275391 36.790527,27.206055 C 37.958984,27.11377 39.171875,27.238281 40.319824,27.07959 C 40.320801,27.072754 40.323242,27.066406 40.32666,27.060059 C 39.238281,26.217773 37.852051,25.551758 36.533691,25.144531" id="path105" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 46.753418,25.144531 C 48.381348,24.661621 50.396973,24.821289 51.437012,26.329102 C 51.991211,27.132813 52.117676,30.24707 50.353027,29.641602 C 50.04541,29.536133 49.793945,28.170899 49.418945,27.856445 C 48.70459,27.256836 47.365235,27.275391 46.49707,27.206055 C 45.329102,27.11377 44.116211,27.238281 42.968262,27.07959 C 42.966797,27.072754 42.964356,27.066406 42.961426,27.060059 C 44.049805,26.217285 45.435547,25.552734 46.753418,25.144531" id="path107" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 40.30957,29.629883 C 40.547852,31.663574 44.07373,30.674805 42.387695,27.875977 C 41.203125,25.90918 40.523926,27.75 40.30957,29.629883" id="path109" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 69.461914,216.98438 C 68.644043,219.00879 67.77832,221.07129 67.217285,223.18555 C 66.711914,225.09082 66.308594,229.74707 68.845215,230.66113 C 71.278809,231.53809 72.162109,227.33105 72.130371,225.67871 C 72.08252,223.19434 70.901856,220.65918 70.01416,218.37695 C 69.833008,217.91211 69.648926,217.44629 69.461914,216.98438" id="path111" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 69.44873,216.93848 C 67.437012,217.78418 65.19873,218.61426 63.418457,219.90039 C 61.837891,221.04199 59.321777,224.4248 60.789551,226.49414 C 62.271973,228.58301 65.364258,226.18945 66.363281,224.77832 C 67.796387,222.75488 68.469238,219.9873 69.22168,217.65332 C 69.29834,217.41602 69.373535,217.1748 69.44873,216.93848" id="path113" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 69.455566,216.93848 C 71.467285,217.78418 73.705566,218.61426 75.48584,219.90039 C 77.066406,221.04199 79.582031,224.4248 78.114258,226.49414 C 76.632324,228.58301 73.540039,226.18945 72.540527,224.77832 C 71.07959,222.71484 70.395996,219.87891 69.632324,217.49609 C 69.572754,217.31055 69.51416,217.12305 69.455566,216.93848" id="path115" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 64.301758,216.66113 C 62.674316,217.14355 60.658691,216.9834 59.619141,215.47559 C 59.064941,214.67285 58.938477,211.55762 60.703125,212.16309 C 61.010254,212.26855 61.262207,213.63379 61.636719,213.94824 C 62.351563,214.54785 63.69043,214.53027 64.559082,214.59863 C 65.727051,214.69141 66.940918,214.56738 68.087891,214.72559 C 68.088867,214.7334 68.091309,214.73926 68.094727,214.74512 C 67.006836,215.58789 65.620117,216.25293 64.301758,216.66113" id="path117" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 74.521973,216.66113 C 76.149414,217.14355 78.165527,216.9834 79.205078,215.47559 C 79.759277,214.67285 79.885742,211.55859 78.121094,212.16309 C 77.813965,212.26855 77.562012,213.63379 77.187012,213.94824 C 76.472168,214.54785 75.133789,214.53027 74.265137,214.59863 C 73.097168,214.69141 71.883301,214.56738 70.736328,214.72559 C 70.734863,214.73242 70.732422,214.73926 70.729492,214.74512 C 71.816895,215.58789 73.204102,216.25293 74.521973,216.66113" id="path119" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 68.077637,212.17578 C 68.31543,210.1416 71.841797,211.12988 70.155762,213.92871 C 68.971191,215.89648 68.293457,214.05664 68.077637,212.17578" id="path121" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 97.47998,216.98438 C 96.661621,219.00879 95.796387,221.07129 95.23584,223.18555 C 94.72998,225.09082 94.327637,229.74609 96.862793,230.66113 C 99.296387,231.53809 100.18115,227.33105 100.14893,225.67871 C 100.10107,223.19629 98.921387,220.65723 98.032715,218.37695 C 97.851074,217.91211 97.66748,217.44629 97.47998,216.98438" id="path123" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 97.467285,216.93848 C 95.455566,217.78418 93.216309,218.61426 91.437012,219.90039 C 89.856934,221.04199 87.340332,224.4248 88.808106,226.49414 C 90.290527,228.58301 93.382324,226.18945 94.382324,224.77832 C 95.843262,222.71484 96.525879,219.87891 97.290527,217.49609 C 97.349121,217.31152 97.408692,217.12207 97.467285,216.93848" id="path125" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 97.474121,216.93848 C 99.48584,217.78418 101.72412,218.61426 103.50439,219.90039 C 105.08545,221.04199 107.60107,224.4248 106.13232,226.49414 C 104.6499,228.58301 101.55811,226.18945 100.55908,224.77832 C 99.098145,222.71484 98.414551,219.87891 97.649902,217.49609 C 97.591309,217.31152 97.532715,217.12207 97.474121,216.93848" id="path127" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 92.319824,216.66113 C 90.692871,217.14355 88.677246,216.9834 87.637207,215.47559 C 87.083496,214.67285 86.956543,211.55762 88.721191,212.16309 C 89.028809,212.26855 89.280762,213.63379 89.655762,213.94824 C 90.370606,214.54785 91.708496,214.53027 92.577637,214.59863 C 93.744629,214.69141 94.959473,214.56738 96.105957,214.72559 C 96.106934,214.73242 96.109863,214.73926 96.112793,214.74512 C 95.024902,215.58789 93.638184,216.25293 92.319824,216.66113" id="path129" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 102.54053,216.66113 C 104.16748,217.14355 106.18311,216.9834 107.22412,215.47559 C 107.77686,214.67285 107.90381,211.55859 106.14014,212.16309 C 105.83154,212.26855 105.58057,213.63379 105.20557,213.94824 C 104.49072,214.54785 103.15186,214.53027 102.28271,214.59863 C 101.11572,214.69141 99.901855,214.56738 98.754394,214.72559 C 98.753418,214.7334 98.751465,214.73926 98.747559,214.74512 C 99.835449,215.58789 101.22217,216.25293 102.54053,216.66113" id="path131" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 96.095215,212.17578 C 96.334473,210.1416 99.859863,211.12988 98.174316,213.92871 C 96.989746,215.89648 96.312012,214.05664 96.095215,212.17578" id="path133" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 125.49854,216.98438 C 124.68018,219.00879 123.81494,221.07129 123.25342,223.18555 C 122.74854,225.09082 122.34521,229.74707 124.88232,230.66113 C 127.31592,231.53809 128.19873,227.33105 128.16748,225.67871 C 128.11963,223.19629 126.94092,220.65723 126.05029,218.37695 C 125.86963,217.91211 125.68506,217.44629 125.49854,216.98438" id="path135" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 125.48584,216.93848 C 123.47412,217.78418 121.23486,218.61426 119.45459,219.90039 C 117.87451,221.04199 115.35889,224.4248 116.82568,226.49414 C 118.30811,228.58301 121.40088,226.18945 122.3999,224.77832 C 123.8335,222.75488 124.50635,219.9873 125.25928,217.65332 C 125.33545,217.41602 125.40967,217.1748 125.48584,216.93848" id="path137" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 125.4917,216.93848 C 127.50342,217.78418 129.74268,218.61426 131.52295,219.90039 C 133.10303,221.04199 135.61865,224.4248 134.15088,226.49414 C 132.66943,228.58301 129.57666,226.18945 128.57764,224.77832 C 127.14404,222.75488 126.47217,219.9873 125.71826,217.65332 C 125.64209,217.41602 125.56689,217.1748 125.4917,216.93848" id="path139" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 120.33838,216.66113 C 118.71045,217.14355 116.69482,216.9834 115.65576,215.47559 C 115.10107,214.67285 114.97412,211.55859 116.73975,212.16309 C 117.04639,212.26855 117.29834,213.63379 117.67334,213.94824 C 118.38818,214.54785 119.72705,214.53027 120.59521,214.59863 C 121.76318,214.69141 122.97803,214.56738 124.12451,214.72559 C 124.12549,214.7334 124.12842,214.73926 124.13135,214.74512 C 123.04248,215.58789 121.65771,216.25293 120.33838,216.66113" id="path141" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 130.55811,216.66113 C 132.18604,217.14355 134.20264,216.9834 135.2417,215.47559 C 135.79639,214.67285 135.92236,211.55859 134.15771,212.16309 C 133.85107,212.26855 133.59912,213.63379 133.22412,213.94824 C 132.50928,214.54785 131.17139,214.53027 130.30225,214.59863 C 129.13428,214.69141 127.92041,214.56738 126.77295,214.72559 C 126.771,214.7334 126.76904,214.73926 126.76611,214.74512 C 127.85303,215.58789 129.24072,216.25293 130.55811,216.66113" id="path143" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 124.11475,212.17578 C 124.35205,210.1416 127.87842,211.12988 126.19287,213.92871 C 125.00732,215.89648 124.32959,214.05664 124.11475,212.17578" id="path145" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 41.443848,216.98438 C 40.625,219.00781 39.759766,221.07129 39.19873,223.18555 C 38.693359,225.09082 38.290527,229.74707 40.82666,230.66113 C 43.259766,231.53809 44.144531,227.33105 44.112305,225.67871 C 44.064453,223.19434 42.883789,220.65918 41.995605,218.37695 C 41.814941,217.91211 41.630371,217.44629 41.443848,216.98438" id="path147" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 41.430664,216.93848 C 39.418457,217.78418 37.180176,218.61426 35.399902,219.90039 C 33.819336,221.04199 31.303711,224.4248 32.770996,226.49414 C 34.253418,228.58301 37.345703,226.18848 38.345215,224.77832 C 39.777832,222.75488 40.450684,219.9873 41.203613,217.65332 C 41.280274,217.41699 41.355469,217.1748 41.430664,216.93848" id="path149" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 41.437012,216.93848 C 43.44873,217.78418 45.687012,218.61523 47.467285,219.90039 C 49.047852,221.04102 51.563965,224.4248 50.096191,226.49414 C 48.613769,228.58301 45.521973,226.18945 44.522461,224.77832 C 43.061523,222.71484 42.37793,219.87891 41.613769,217.49609 C 41.554199,217.31152 41.495605,217.12207 41.437012,216.93848" id="path151" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 36.283691,216.66113 C 34.655762,217.14355 32.640137,216.9834 31.600586,215.47559 C 31.046387,214.67285 30.919922,211.55762 32.68457,212.16309 C 32.991699,212.26855 33.244141,213.63379 33.618652,213.94824 C 34.333496,214.54785 35.671875,214.53027 36.540527,214.59863 C 37.708496,214.69141 38.922363,214.56738 40.069336,214.72559 C 40.070801,214.7334 40.073242,214.73926 40.076172,214.74512 C 38.988281,215.58789 37.602051,216.25293 36.283691,216.66113" id="path153" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 46.503418,216.66113 C 48.131348,217.14355 50.146973,216.98438 51.187012,215.47559 C 51.740723,214.67285 51.867188,211.55762 50.102539,212.16309 C 49.79541,212.26855 49.543457,213.63379 49.168945,213.94824 C 48.454102,214.54785 47.115723,214.53027 46.24707,214.59863 C 45.079102,214.69141 43.865234,214.56738 42.717773,214.72559 C 42.716797,214.7334 42.714355,214.73926 42.710938,214.74512 C 43.798828,215.58789 45.185547,216.25293 46.503418,216.66113" id="path155" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 40.059082,212.17578 C 40.297851,210.1416 43.822754,211.12988 42.137695,213.92871 C 40.952637,215.89746 40.275879,214.05664 40.059082,212.17578" id="path157" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.52588,218.3584 C 148.43213,219.37891 150.49365,220.63574 152.60303,221.18066 C 154.52295,221.67676 158.15771,221.19824 158.50732,218.66113 C 158.84521,216.21289 155.38135,215.11328 153.51318,215.33008 C 151.18311,215.60059 148.73975,217.16895 146.69873,218.26172 C 146.64209,218.29199 146.58252,218.32715 146.52588,218.3584" id="path159" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48975,218.37305 C 147.31006,220.97168 149.85498,230.89355 154.43701,228.54883 C 156.66943,227.40723 154.87354,223.62793 153.70459,222.34277 C 152.05518,220.53223 149.31982,219.58105 147.10889,218.63574 C 146.90381,218.54687 146.69482,218.45996 146.48975,218.37305" id="path161" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,218.36523 C 147.30811,215.76465 149.85303,205.83887 154.43701,208.18945 C 156.66748,209.33301 154.87451,213.10059 153.70459,214.3877 C 152.0542,216.2002 149.31885,217.15527 147.10693,218.10449 C 146.8999,218.19238 146.69092,218.27832 146.48584,218.36523" id="path163" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,224.30957 C 146.6167,225.98145 146.69385,228.48926 145.21826,229.70996 C 144.2583,230.50488 141.7749,230.24316 142.34619,228.45996 C 142.45264,228.12988 143.60693,227.78418 143.89404,227.37988 C 144.48779,226.54199 144.40186,224.97754 144.45752,224.0127 C 144.53467,222.66504 144.41162,221.27539 144.56787,219.94238 C 144.57373,219.94043 144.57959,219.93848 144.58447,219.93457 C 145.35498,221.24512 145.90772,222.8252 146.24561,224.30957" id="path165" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,212.52148 C 146.61768,210.84766 146.69678,208.34082 145.21826,207.12012 C 144.2583,206.32715 141.77197,206.58496 142.34619,208.37012 C 142.45166,208.69824 143.60889,209.04395 143.89404,209.44824 C 144.48779,210.28516 144.40186,211.85254 144.45752,212.81738 C 144.53467,214.16602 144.41162,215.55469 144.56787,216.8877 C 144.57373,216.88965 144.57959,216.89258 144.58447,216.89551 C 145.35303,215.58691 145.91162,214.00488 146.24561,212.52148" id="path167" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 142.35693,219.9541 C 140.52881,219.5127 141.31006,215.55957 143.87744,217.55762 C 145.42139,218.75879 144.25732,219.62988 142.35693,219.9541" id="path169" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.52881,186.04199 C 148.43506,187.06445 150.49756,188.31934 152.60693,188.86621 C 154.52295,189.3623 158.1626,188.88379 158.50732,186.3457 C 158.83936,183.89941 155.38232,182.79883 153.51318,183.01465 C 151.18506,183.28223 148.73975,184.85254 146.70264,185.94629 C 146.64502,185.97656 146.58545,186.01172 146.52881,186.04199" id="path171" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,186.05762 C 147.31006,188.65723 149.85303,198.5791 154.43701,196.2334 C 156.66846,195.09082 154.87451,191.31543 153.70459,190.03125 C 152.05225,188.21973 149.31787,187.26758 147.10693,186.31934 C 146.8999,186.23047 146.69092,186.14355 146.48584,186.05762" id="path173" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48975,186.0498 C 147.31006,183.44824 149.85303,173.52832 154.43701,175.87402 C 156.66943,177.0166 154.87451,180.79102 153.70459,182.0752 C 152.0542,183.8877 149.31982,184.83984 147.10889,185.78809 C 146.90381,185.87598 146.69482,185.96289 146.48975,186.0498" id="path175" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,191.98926 C 146.61768,193.66699 146.69678,196.16602 145.21826,197.39063 C 144.26025,198.18457 141.771,197.93457 142.34619,196.14453 C 142.45166,195.81738 143.60889,195.4707 143.89404,195.06738 C 144.48779,194.22949 144.40186,192.66309 144.45752,191.69727 C 144.53467,190.34863 144.41162,188.95996 144.56787,187.62695 C 144.57373,187.625 144.57959,187.62207 144.58447,187.61914 C 145.35303,188.92578 145.91064,190.50684 146.24561,191.98926" id="path177" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,180.20605 C 146.6167,178.53418 146.69385,176.02637 145.21826,174.80469 C 144.25928,174.01074 141.76904,174.27051 142.34619,176.05469 C 142.45264,176.38184 143.60791,176.72754 143.89404,177.13184 C 144.48779,177.9707 144.40186,179.53613 144.45752,180.50195 C 144.53467,181.85059 144.41162,183.23926 144.56787,184.57227 C 144.57373,184.57422 144.57959,184.57715 144.58447,184.58008 C 145.35498,183.27051 145.90772,181.68945 146.24561,180.20605" id="path179" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 142.35693,187.63867 C 140.52881,187.19824 141.31201,183.23926 143.87744,185.2373 C 145.42334,186.44238 144.25732,187.31348 142.35693,187.63867" id="path181" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.52588,153.72559 C 148.43018,154.75 150.49756,156.00586 152.60693,156.5498 C 154.52686,157.0459 158.15771,156.56738 158.50732,154.03027 C 158.84424,151.58301 155.38232,150.47852 153.51318,150.69434 C 151.18213,150.96484 148.73877,152.53711 146.69873,153.62988 C 146.64209,153.66113 146.5835,153.69531 146.52588,153.72559" id="path183" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,153.74121 C 147.31006,156.34277 149.85303,166.26367 154.43701,163.91699 C 156.66846,162.77539 154.87451,159 153.70459,157.71582 C 152.05225,155.90332 149.31787,154.95117 147.10693,154.00293 C 146.8999,153.91504 146.69092,153.82813 146.48584,153.74121" id="path185" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,153.7334 C 147.31006,151.13379 149.85303,141.21191 154.43701,143.55762 C 156.66846,144.7002 154.87451,148.47559 153.70459,149.75977 C 152.05225,151.57129 149.31787,152.52441 147.10693,153.47168 C 146.8999,153.56055 146.69092,153.64746 146.48584,153.7334" id="path187" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,159.67773 C 146.6167,161.35059 146.69385,163.85742 145.21826,165.0791 C 144.2583,165.87402 141.77197,165.61328 142.34619,163.8291 C 142.45166,163.50098 143.60889,163.15527 143.89404,162.75098 C 144.48779,161.91309 144.40186,160.34668 144.45752,159.38184 C 144.53467,158.04004 144.39209,156.62207 144.57764,155.30762 C 145.36279,156.58301 145.91064,158.20703 146.24561,159.67773" id="path189" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,147.88965 C 146.6167,146.21777 146.69385,143.70996 145.21826,142.48926 C 144.2583,141.69336 141.77197,141.9541 142.34619,143.73926 C 142.45166,144.06738 143.60889,144.41309 143.89404,144.81641 C 144.48779,145.6543 144.40186,147.2207 144.45752,148.18652 C 144.53467,149.52832 144.39209,150.94629 144.57764,152.26074 C 145.36279,150.98535 145.91064,149.36035 146.24561,147.88965" id="path191" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 142.35693,155.32324 C 140.52783,154.88281 141.31201,150.93164 143.87744,152.92188 C 145.42529,154.12402 144.25342,154.99414 142.35693,155.32324" id="path193" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.52588,89.094727 C 148.43115,90.116699 150.49561,91.374512 152.60303,91.918457 C 154.52295,92.414063 158.15771,91.935547 158.50732,89.398438 C 158.84521,86.950195 155.38135,85.850098 153.51318,86.066895 C 151.18213,86.337891 148.73975,87.90625 146.69873,88.998535 C 146.64209,89.029785 146.58252,89.063965 146.52588,89.094727" id="path195" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48975,89.110352 C 147.31006,91.709961 149.85498,101.63037 154.43701,99.285645 C 156.66943,98.143555 154.87354,94.364746 153.70459,93.080078 C 152.05615,91.27002 149.31982,90.317871 147.10889,89.37207 C 146.90381,89.283692 146.69482,89.196289 146.48975,89.110352" id="path197" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,89.102539 C 147.30811,86.501465 149.854,76.575684 154.43701,78.926758 C 156.66748,80.070313 154.87451,83.838379 153.70459,85.124512 C 152.0542,86.9375 149.31787,87.891601 147.10693,88.84082 C 146.8999,88.929199 146.69092,89.015625 146.48584,89.102539" id="path199" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,95.045898 C 146.6167,96.718262 146.69385,99.226563 145.21826,100.44775 C 144.20068,101.29102 141.80615,100.9375 142.36963,99.151367 C 142.45459,98.883789 143.69092,98.400879 143.95752,97.985352 C 144.51514,97.114258 144.41748,95.543945 144.46826,94.561524 C 144.53271,93.286133 144.38721,91.913086 144.58447,90.671875 C 145.35498,91.981934 145.90771,93.5625 146.24561,95.045899" id="path201" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,83.258789 C 146.61768,81.584961 146.69678,79.079102 145.21826,77.857422 C 144.25928,77.063965 141.771,77.321777 142.34619,79.107422 C 142.45166,79.435547 143.60889,79.78125 143.89404,80.185059 C 144.48779,81.022461 144.40186,82.589844 144.45752,83.554688 C 144.53467,84.90332 144.41162,86.291992 144.56787,87.625 C 144.57373,87.626953 144.57959,87.629395 144.58447,87.632813 C 145.354,86.324219 145.91162,84.742188 146.24561,83.258789" id="path203" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 142.35693,90.691406 C 140.52881,90.25 141.31006,86.297363 143.87744,88.294434 C 145.42139,89.496094 144.25732,90.366211 142.35693,90.691406" id="path205" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.52881,56.779297 C 148.43506,57.802246 150.49756,59.055664 152.60693,59.603027 C 154.52393,60.100098 158.1626,59.620606 158.50732,57.083008 C 158.83936,54.635742 155.38232,53.536133 153.51318,53.751465 C 151.18506,54.019531 148.73975,55.59082 146.70264,56.682617 C 146.64502,56.713867 146.58545,56.748535 146.52881,56.779297" id="path207" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,56.794434 C 147.31006,59.395019 149.85303,69.316406 154.43701,66.970215 C 156.66846,65.828613 154.87451,62.052734 153.70459,60.768555 C 152.05225,58.956543 149.31787,58.004883 147.10693,57.056152 C 146.8999,56.967774 146.69092,56.880859 146.48584,56.794434" id="path209" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48975,56.786621 C 147.31006,54.185547 149.85303,44.265137 154.43701,46.611328 C 156.66943,47.75293 154.87354,51.52832 153.70459,52.8125 C 152.0542,54.624512 149.3208,55.577148 147.10889,56.525391 C 146.90381,56.613281 146.69482,56.700195 146.48975,56.786621" id="path211" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,62.726563 C 146.61768,64.404297 146.69678,66.903809 145.21826,68.12793 C 144.26025,68.921875 141.77002,68.671875 142.34619,66.881836 C 142.45166,66.553711 143.60889,66.208008 143.89404,65.804199 C 144.48779,64.966797 144.40186,63.399414 144.45752,62.43457 C 144.53467,61.085938 144.41162,59.697266 144.56787,58.364258 C 144.57373,58.362305 144.57959,58.359863 144.58447,58.356445 C 145.35303,59.663574 145.91064,61.244629 146.24561,62.726563" id="path213" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,50.942871 C 146.61768,49.270508 146.69385,46.763184 145.21826,45.541992 C 144.25928,44.748047 141.7749,45.003906 142.34619,46.788086 C 142.45264,47.119141 143.60693,47.463379 143.89404,47.869141 C 144.48779,48.708008 144.40186,50.273438 144.45752,51.239258 C 144.53467,52.587891 144.41162,53.976563 144.56787,55.30957 C 144.57373,55.311524 144.57959,55.313965 144.58447,55.317383 C 145.35498,54.007813 145.90772,52.426758 146.24561,50.942871" id="path215" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 142.35693,58.375488 C 140.52881,57.936524 141.31104,53.976563 143.87744,55.975098 C 145.42334,57.179199 144.25732,58.050781 142.35693,58.375488" id="path217" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.52588,24.463379 C 148.4292,25.487793 150.49756,26.743164 152.60693,27.287109 C 154.52686,27.782715 158.15771,27.303711 158.50732,24.767578 C 158.84424,22.319824 155.38232,21.215332 153.51318,21.431641 C 151.18213,21.70166 148.73877,23.274414 146.69873,24.367187 C 146.64209,24.398437 146.5835,24.432617 146.52588,24.463379" id="path219" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,24.478516 C 147.31006,27.079102 149.85303,37.000977 154.43701,34.654297 C 156.66846,33.512695 154.87451,29.736816 153.70459,28.452637 C 152.05225,26.640625 149.31787,25.688965 147.10693,24.740234 C 146.8999,24.652344 146.69092,24.56543 146.48584,24.478516" id="path221" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,24.471191 C 147.31006,21.870606 149.85303,11.949219 154.43701,14.29541 C 156.66846,15.437012 154.87451,19.212891 153.70459,20.49707 C 152.05225,22.309082 149.31787,23.260742 147.10693,24.209473 C 146.8999,24.297851 146.69092,24.384766 146.48584,24.471191" id="path223" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,30.415039 C 146.6167,32.086914 146.69385,34.594727 145.21826,35.816406 C 144.2583,36.611328 141.77197,36.350586 142.34619,34.565918 C 142.45166,34.238281 143.60889,33.89209 143.89404,33.48877 C 144.48779,32.650879 144.40186,31.083984 144.45752,30.118652 C 144.53467,28.776856 144.39209,27.358399 144.57764,26.044922 C 145.36279,27.319336 145.91064,28.944824 146.24561,30.415039" id="path225" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,18.627441 C 146.6167,16.955078 146.69385,14.447754 145.21826,13.226074 C 144.2583,12.431152 141.77197,12.691406 142.34619,14.476563 C 142.45166,14.804199 143.60889,15.149902 143.89404,15.553711 C 144.48779,16.391602 144.40186,17.958008 144.45752,18.923828 C 144.53467,20.265625 144.39209,21.683594 144.57764,22.997559 C 145.36279,21.722168 145.91064,20.097656 146.24561,18.627441" id="path227" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 142.35693,26.060059 C 140.52783,25.620117 141.31201,21.668945 143.87744,23.65918 C 145.42529,24.860352 144.25342,25.731445 142.35693,26.060059" id="path229" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.52588,121.41016 C 148.4292,122.43457 150.49756,123.69043 152.60693,124.23438 C 154.52686,124.72949 158.15771,124.25098 158.50732,121.71387 C 158.84424,119.26563 155.38232,118.16602 153.51318,118.38281 C 151.18213,118.65381 148.73975,120.22168 146.69873,121.31445 C 146.64209,121.34473 146.5835,121.37988 146.52588,121.41016" id="path231" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,121.42578 C 147.31006,124.02637 149.85303,133.94824 154.43701,131.60156 C 156.66846,130.45996 154.87451,126.68457 153.70459,125.39941 C 152.05225,123.58789 149.31787,122.63574 147.10693,121.6875 C 146.8999,121.59863 146.69092,121.5127 146.48584,121.42578" id="path233" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.48584,121.41797 C 147.31006,118.81787 149.85303,108.896 154.43701,111.24268 C 156.66846,112.38428 154.87451,116.15967 153.70459,117.44385 C 152.05225,119.25586 149.31787,120.2085 147.10693,121.15723 C 146.8999,121.24512 146.69092,121.33105 146.48584,121.41797" id="path235" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,127.3623 C 146.6167,129.03418 146.69385,131.54199 145.21826,132.7627 C 144.25732,133.55957 141.7749,133.29785 142.34619,131.5127 C 142.45166,131.18457 143.60791,130.83496 143.89404,130.43262 C 144.48779,129.59375 144.40186,128.02734 144.45752,127.06152 C 144.53467,125.71484 144.41162,124.32715 144.56787,122.99512 C 144.57373,122.99316 144.57959,122.99121 144.58447,122.9873 C 145.35498,124.29785 145.90772,125.87793 146.24561,127.3623" id="path237" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 146.24561,115.57422 C 146.6167,113.90088 146.69482,111.39551 145.21826,110.17285 C 144.2583,109.37793 141.77197,109.63818 142.34619,111.42334 C 142.45166,111.75098 143.60889,112.09766 143.89404,112.50098 C 144.48779,113.33887 144.40186,114.90527 144.45752,115.87061 C 144.53467,117.2124 144.39209,118.63037 144.57764,119.94482 C 145.36084,118.66846 145.91064,117.04492 146.24561,115.57422" id="path239" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 142.35693,123.00293 C 140.53271,122.57422 141.30908,118.60742 143.87744,120.61035 C 145.41748,121.81152 144.25342,122.68457 142.35693,123.00293" id="path241" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.816895,218.3584 C 18.911133,219.37891 16.848633,220.63574 14.739746,221.18066 C 12.819824,221.67676 9.1855469,221.19824 8.8359375,218.66113 C 8.4980469,216.21289 11.960938,215.11328 13.829102,215.33008 C 16.160156,215.60059 18.603516,217.16895 20.643555,218.26172 C 20.701172,218.29199 20.760742,218.32715 20.816895,218.3584" id="path243" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.853516,218.37305 C 20.033203,220.97168 17.487793,230.89258 12.905273,228.54883 C 10.67334,227.40723 12.469238,223.62793 13.638672,222.34277 C 15.288086,220.53223 18.022949,219.58105 20.233398,218.63574 C 20.439453,218.54687 20.647461,218.45996 20.853516,218.37305" id="path245" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,218.36523 C 20.035156,215.76367 17.489258,205.83984 12.905273,208.18945 C 10.675293,209.33301 12.467773,213.10059 13.638672,214.3877 C 15.289551,216.19922 18.023437,217.15527 20.236328,218.10449 C 20.441895,218.19238 20.651367,218.27832 20.856934,218.36523" id="path247" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,224.30957 C 20.725586,225.98145 20.648926,228.48926 22.124512,229.70996 C 23.084961,230.50488 25.56836,230.24316 24.996094,228.45996 C 24.890137,228.12988 23.73584,227.78516 23.448731,227.37988 C 22.854981,226.54199 22.94043,224.97754 22.884766,224.0127 C 22.807617,222.66504 22.931152,221.27539 22.774902,219.94238 C 22.769043,219.94043 22.763184,219.93848 22.757813,219.93457 C 21.98877,221.24512 21.435547,222.8252 21.097168,224.30957" id="path249" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,212.52148 C 20.724121,210.84766 20.646484,208.34082 22.124512,207.12012 C 23.084961,206.32715 25.570801,206.58496 24.996094,208.37012 C 24.890625,208.69824 23.734863,209.04395 23.448731,209.44824 C 22.854981,210.28516 22.94043,211.85254 22.884766,212.81738 C 22.807617,214.16504 22.931152,215.55566 22.774902,216.8877 C 22.769043,216.88965 22.763184,216.89258 22.757813,216.89551 C 21.989258,215.58691 21.431152,214.00488 21.097168,212.52148" id="path251" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 24.986328,219.9541 C 26.814941,219.51172 26.031738,215.56055 23.465332,217.55762 C 21.920898,218.75879 23.085449,219.62988 24.986328,219.9541" id="path253" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.813477,186.04199 C 18.90625,187.06445 16.846191,188.31934 14.736328,188.86621 C 12.818848,189.3623 9.1806641,188.88379 8.8359376,186.3457 C 8.5034181,183.89941 11.960449,182.79883 13.829102,183.01465 C 16.158203,183.28223 18.603028,184.85352 20.640137,185.94629 C 20.698242,185.97656 20.756836,186.01172 20.813477,186.04199" id="path255" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,186.05762 C 20.032715,188.6582 17.490234,198.5791 12.905273,196.2334 C 10.674805,195.0918 12.467773,191.31543 13.638672,190.03125 C 15.290527,188.21973 18.023926,187.26758 20.236328,186.31934 C 20.441895,186.23047 20.651367,186.14355 20.856934,186.05762" id="path257" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.853516,186.0498 C 20.032227,183.44824 17.489746,173.52832 12.905273,175.87402 C 10.674316,177.01563 12.46875,180.79004 13.638672,182.0752 C 15.288574,183.88672 18.021973,184.84082 20.233398,185.78809 C 20.438965,185.87598 20.647949,185.96289 20.853516,186.0498" id="path259" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,191.98926 C 20.724609,193.66699 20.646484,196.16699 22.124512,197.39063 C 23.083008,198.18457 25.572266,197.93457 24.996094,196.14453 C 24.890625,195.81641 23.734863,195.4707 23.448731,195.06738 C 22.854981,194.22949 22.94043,192.66309 22.884766,191.69727 C 22.807617,190.34863 22.931152,188.95996 22.774902,187.62695 C 22.769043,187.625 22.763184,187.62207 22.757813,187.61914 C 21.990234,188.92676 21.432129,190.50684 21.097168,191.98926" id="path261" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,180.20605 C 20.725586,178.5332 20.648926,176.02637 22.124512,174.80469 C 23.083985,174.01074 25.573731,174.27051 24.996094,176.05469 C 24.890137,176.38184 23.734863,176.72754 23.448731,177.13184 C 22.854492,177.9707 22.94043,179.53613 22.884766,180.50195 C 22.807617,181.85059 22.931152,183.23926 22.774902,184.57227 C 22.769043,184.57422 22.763184,184.57715 22.757813,184.58008 C 21.98877,183.26953 21.435547,181.69043 21.097168,180.20605" id="path263" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 24.986328,187.63867 C 26.81543,187.19824 26.030273,183.23926 23.465332,185.2373 C 21.918945,186.44238 23.085938,187.31348 24.986328,187.63867" id="path265" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.816895,153.72559 C 18.913086,154.75 16.844727,156.00586 14.736328,156.5498 C 12.816406,157.0459 9.1850586,156.56738 8.8359375,154.03027 C 8.4990234,151.58301 11.959473,150.47852 13.829102,150.69434 C 16.160645,150.96484 18.604004,152.53711 20.643555,153.62988 C 20.701172,153.66113 20.760254,153.69629 20.816895,153.72559" id="path267" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,153.74121 C 20.032715,156.34277 17.490723,166.2627 12.905273,163.91699 C 10.674805,162.77637 12.467773,159 13.638672,157.71582 C 15.290039,155.9043 18.024414,154.95215 20.236328,154.00293 C 20.441895,153.91504 20.651367,153.82813 20.856934,153.74121" id="path269" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,153.7334 C 20.032715,151.13281 17.490234,141.21289 12.905273,143.55762 C 10.674316,144.69922 12.468262,148.47559 13.638672,149.75977 C 15.290527,151.57129 18.024414,152.52344 20.236328,153.47168 C 20.441895,153.56055 20.651367,153.64746 20.856934,153.7334" id="path271" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,159.67773 C 20.725586,161.35059 20.648926,163.85742 22.124512,165.0791 C 23.084961,165.87402 25.570801,165.61328 24.996094,163.8291 C 24.890625,163.50098 23.734863,163.15527 23.448731,162.75098 C 22.854492,161.91309 22.94043,160.34668 22.884766,159.38184 C 22.808106,158.04004 22.95166,156.62109 22.764649,155.30762 C 21.979981,156.58398 21.432617,158.20703 21.097168,159.67773" id="path273" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,147.88965 C 20.725586,146.21777 20.648926,143.70996 22.124512,142.48926 C 23.084961,141.69434 25.570313,141.9541 24.996094,143.73926 C 24.890625,144.06738 23.734375,144.41309 23.448731,144.81641 C 22.854492,145.6543 22.94043,147.2207 22.884766,148.18652 C 22.808106,149.52832 22.95166,150.94727 22.764649,152.26074 C 21.979492,150.98438 21.432617,149.36035 21.097168,147.88965" id="path275" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 24.986328,155.32324 C 26.815918,154.88184 26.029785,150.93262 23.465332,152.92188 C 21.916504,154.12402 23.089844,154.99414 24.986328,155.32324" id="path277" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.816895,89.094727 C 18.912598,90.116699 16.847656,91.374024 14.739746,91.918457 C 12.819824,92.414063 9.1855469,91.935547 8.8359375,89.398438 C 8.4985352,86.950195 11.960938,85.850098 13.829102,86.066895 C 16.160645,86.337891 18.603027,87.90625 20.643555,88.998535 C 20.701172,89.029297 20.760254,89.064453 20.816895,89.094727" id="path279" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.853516,89.110352 C 20.032715,91.709961 17.487793,101.62988 12.905273,99.285645 C 10.67334,98.144043 12.469238,94.364746 13.638672,93.080078 C 15.287598,91.27002 18.022949,90.317383 20.233398,89.37207 C 20.438965,89.283692 20.647949,89.196289 20.853516,89.110352" id="path281" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,89.102539 C 20.035156,86.500977 17.48877,76.57666 12.905273,78.926758 C 10.675293,80.070313 12.467285,83.838379 13.638672,85.124512 C 15.289551,86.9375 18.023926,87.891601 20.236328,88.84082 C 20.441895,88.929199 20.651855,89.015625 20.856934,89.102539" id="path283" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,95.045898 C 20.725586,96.71875 20.648926,99.226074 22.124512,100.44775 C 23.143067,101.29053 25.53711,100.9375 24.973145,99.151367 C 24.888672,98.883789 23.651367,98.400879 23.385254,97.985352 C 22.827149,97.114258 22.924805,95.543945 22.875,94.561524 C 22.810059,93.286133 22.956055,91.912109 22.757813,90.671875 C 21.988281,91.982422 21.435547,93.5625 21.097168,95.045899" id="path285" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,83.258789 C 20.724609,81.584961 20.646484,79.078613 22.124512,77.857422 C 23.084473,77.064453 25.571289,77.321777 24.996094,79.107422 C 24.890625,79.435547 23.734863,79.78125 23.448731,80.185059 C 22.854981,81.022461 22.94043,82.589844 22.884766,83.554688 C 22.807617,84.90332 22.931152,86.29248 22.774902,87.625 C 22.769043,87.626953 22.763184,87.629395 22.757813,87.632813 C 21.989258,86.32373 21.431152,84.742188 21.097168,83.258789" id="path287" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 24.986328,90.691406 C 26.814941,90.249023 26.031738,86.297852 23.465332,88.294434 C 21.92041,89.496582 23.085938,90.366211 24.986328,90.691406" id="path289" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.813477,56.779297 C 18.907227,57.801758 16.845703,59.055664 14.736328,59.603027 C 12.818848,60.100098 9.1806641,59.620606 8.8359376,57.083008 C 8.5034181,54.635742 11.960449,53.536133 13.829102,53.751465 C 16.158203,54.019531 18.602539,55.591309 20.640137,56.682617 C 20.698242,56.713867 20.756836,56.748535 20.813477,56.779297" id="path291" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,56.794434 C 20.032715,59.395508 17.490234,69.31543 12.905273,66.970215 C 10.674805,65.829102 12.468262,62.052734 13.638672,60.768555 C 15.290527,58.957031 18.023926,58.004883 20.236328,57.056152 C 20.441895,56.967774 20.651855,56.880859 20.856934,56.794434" id="path293" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.853516,56.786621 C 20.032715,54.185547 17.489746,44.265625 12.905273,46.611328 C 10.673828,47.752441 12.46875,51.52832 13.638672,52.8125 C 15.289062,54.624023 18.021973,55.577148 20.233398,56.525391 C 20.438965,56.613281 20.647949,56.700195 20.853516,56.786621" id="path295" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,62.726563 C 20.724609,64.404297 20.646484,66.904297 22.124512,68.12793 C 23.083008,68.921387 25.572266,68.671875 24.996094,66.881836 C 24.890625,66.553711 23.734863,66.208008 23.448731,65.804199 C 22.854981,64.966797 22.94043,63.399414 22.884766,62.43457 C 22.807617,61.085938 22.931152,59.696777 22.774902,58.364258 C 22.769043,58.362305 22.763184,58.359863 22.757813,58.356445 C 21.990234,59.664063 21.432129,61.244141 21.097168,62.726563" id="path297" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,50.942871 C 20.725586,49.270019 20.648926,46.763184 22.124512,45.541992 C 23.083496,44.748047 25.567383,45.003906 24.996094,46.788086 C 24.890137,47.119141 23.736328,47.463379 23.448731,47.869141 C 22.854492,48.708008 22.94043,50.273438 22.884766,51.239258 C 22.807617,52.587402 22.931152,53.976563 22.774902,55.30957 C 22.768555,55.311524 22.763184,55.313965 22.757813,55.317383 C 21.98877,54.006836 21.435547,52.426758 21.097168,50.942871" id="path299" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 24.986328,58.375488 C 26.814941,57.935547 26.030762,53.976563 23.465332,55.975098 C 21.919434,57.179199 23.085938,58.050781 24.986328,58.375488" id="path301" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.816895,24.463379 C 18.913086,25.487793 16.844727,26.743164 14.736328,27.287109 C 12.816406,27.782715 9.1850586,27.303711 8.8359375,24.767578 C 8.4990234,22.319824 11.959473,21.215332 13.829102,21.431641 C 16.160645,21.70166 18.604004,23.274414 20.643555,24.367187 C 20.701172,24.398437 20.760254,24.432617 20.816895,24.463379" id="path303" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,24.478516 C 20.032715,27.080078 17.490234,37 12.905273,34.654297 C 10.674805,33.513184 12.468262,29.736816 13.638672,28.452637 C 15.290527,26.641113 18.024414,25.689453 20.236328,24.740234 C 20.441895,24.652344 20.651855,24.56543 20.856934,24.478516" id="path305" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,24.471191 C 20.033203,21.870117 17.490234,11.950195 12.905273,14.29541 C 10.674316,15.436523 12.468262,19.212891 13.638672,20.49707 C 15.290527,22.308594 18.024414,23.260742 20.236328,24.209473 C 20.441895,24.297851 20.651855,24.384766 20.856934,24.471191" id="path307" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,30.415039 C 20.725586,32.087402 20.648926,34.594727 22.124512,35.816406 C 23.084961,36.611328 25.570801,36.350586 24.996094,34.565918 C 24.890625,34.237793 23.734863,33.89209 23.448731,33.48877 C 22.854492,32.650879 22.94043,31.083984 22.884766,30.118652 C 22.808106,28.777344 22.95166,27.35791 22.764649,26.044922 C 21.979492,27.320313 21.432129,28.944336 21.097168,30.415039" id="path309" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,18.627441 C 20.725586,16.95459 20.648926,14.447754 22.124512,13.226074 C 23.084961,12.431152 25.570801,12.691406 24.996094,14.476563 C 24.890625,14.804199 23.734863,15.149902 23.448731,15.553711 C 22.854492,16.391602 22.94043,17.958008 22.884766,18.923828 C 22.808106,20.265137 22.95166,21.684082 22.764649,22.997559 C 21.979981,21.720703 21.432617,20.098145 21.097168,18.627441" id="path311" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 24.986328,26.060059 C 26.81543,25.619141 26.030273,21.669434 23.465332,23.65918 C 21.916504,24.86084 23.089844,25.731934 24.986328,26.060059" id="path313" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.816895,121.41016 C 18.913086,122.43457 16.844238,123.69043 14.736328,124.23438 C 12.815918,124.72949 9.1850586,124.25098 8.8359375,121.71387 C 8.4985352,119.26563 11.960449,118.16602 13.829102,118.38281 C 16.160645,118.65381 18.603027,120.22168 20.643555,121.31445 C 20.701172,121.34473 20.760254,121.37988 20.816895,121.41016" id="path315" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,121.42578 C 20.032715,124.02637 17.490234,133.94629 12.905273,131.60156 C 10.674805,130.45996 12.468262,126.68457 13.638672,125.39941 C 15.290527,123.58887 18.024414,122.63574 20.236328,121.6875 C 20.441895,121.59863 20.651367,121.5127 20.856934,121.42578" id="path317" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 20.856934,121.41797 C 20.032715,118.81689 17.490234,108.89697 12.905273,111.24268 C 10.674805,112.38379 12.468262,116.15967 13.638672,117.44385 C 15.290527,119.25537 18.023926,120.20801 20.236328,121.15723 C 20.441895,121.24512 20.651367,121.33105 20.856934,121.41797" id="path319" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,127.3623 C 20.725586,129.03516 20.648926,131.54199 22.124512,132.7627 C 23.085938,133.55957 25.567871,133.29785 24.996094,131.5127 C 24.890625,131.18359 23.734863,130.83594 23.448731,130.43262 C 22.854492,129.59473 22.94043,128.02734 22.884766,127.06152 C 22.807617,125.71484 22.931152,124.32715 22.774902,122.99512 C 22.769043,122.99316 22.763184,122.99121 22.757813,122.9873 C 21.98877,124.29785 21.435547,125.87793 21.097168,127.3623" id="path321" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 21.097168,115.57422 C 20.727051,113.90039 20.647949,111.39551 22.124512,110.17285 C 23.084961,109.37793 25.570801,109.63867 24.996094,111.42334 C 24.890625,111.75098 23.734375,112.09717 23.448731,112.50098 C 22.854492,113.33887 22.94043,114.90527 22.884766,115.87061 C 22.808106,117.21191 22.95166,118.63135 22.764649,119.94482 C 21.981445,118.66748 21.432617,117.04541 21.097168,115.57422" id="path323" />
      <path style={{ fillRule: 'evenodd', stroke: 'none' }} d="M 24.986328,123.00293 C 26.810547,122.57324 26.032715,118.60742 23.465332,120.61035 C 21.925293,121.81152 23.089355,122.68457 24.986328,123.00293" id="path325" />
      <path style={{ fill: 'none', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 73.150879,146.58203 L 73.177734,146.58203" id="path327" />
      <path style={{ fillRule: 'evenodd', stroke: '#fb0f0c', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 75.666016,112.24658 C 77.314453,112.24658 91.676269,112.11035 91.676269,112.38086 C 91.676269,115.64453 91.676269,118.9082 91.676269,122.17187 C 91.676269,124.05664 91.676269,125.94238 91.676269,127.82715 C 91.676269,128.25977 91.987793,130.37891 91.676269,130.71289 C 90.657715,131.80762 75.666016,130.06836 75.666016,130.5791 C 75.666016,124.46777 75.666016,118.35742 75.666016,112.24658" id="path329" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: 'none' }} d="M 76.666504,115.70898 C 77.333984,114.93945 78.000977,114.16992 78.667969,113.40088 C 79.001465,114.55469 79.334961,115.70898 79.668457,116.86328 C 78.667969,116.47852 77.66748,116.09375 76.666504,115.70898" id="path331" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: 'none' }} d="M 88.675293,113.40088 C 89.341309,114.16992 90.009277,114.93945 90.675293,115.70898 C 89.675293,116.09375 88.675293,116.47852 87.67334,116.86328 C 88.007324,115.70898 88.341309,114.55469 88.675293,113.40088" id="path333" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: 'none' }} d="M 88.737793,129.49316 C 89.404785,128.72363 90.071777,127.9541 90.739746,127.18457 C 89.737793,126.7998 88.737793,126.41504 87.737793,126.03027 C 88.070801,127.18457 88.403809,128.33887 88.737793,129.49316" id="path335" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: 'none' }} d="M 78.667969,129.55859 C 78.000977,128.78906 77.333984,128.02051 76.666504,127.25098 C 77.667481,126.86621 78.667969,126.48145 79.668457,126.09668 C 79.334961,127.25098 79.001465,128.4043 78.667969,129.55859" id="path337" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: 'none' }} d="M 83.671387,112.24658 C 83.011719,114.5293 82.352051,116.81152 81.692383,119.09375 C 81.446777,119.94336 76.622559,121.1123 75.666016,121.47949 C 76.622559,121.84766 81.446777,123.01563 81.692383,123.86621 C 82.352051,126.14746 83.011719,128.43066 83.671387,130.71289 C 84.331543,128.43066 84.990723,126.14746 85.649902,123.86621 C 85.895996,123.01563 90.720215,121.84766 91.676269,121.47949 C 90.720215,121.1123 85.895996,119.94336 85.649902,119.09375 C 84.990723,116.81152 84.331543,114.5293 83.671387,112.24658" id="path339" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.70361,115.70898 C 132.37744,117.21191 132.05225,118.71484 131.72607,120.21777 C 131.61279,120.7417 128.31396,121.30273 127.70068,121.47949 C 128.31396,121.65723 131.61279,122.21777 131.72607,122.74121 C 132.05225,124.24414 132.37744,125.74707 132.70361,127.25098 C 133.02881,125.74707 133.35498,124.24414 133.68115,122.74121 C 133.79443,122.21777 137.09326,121.65723 137.70654,121.47949 C 137.09326,121.30273 133.79443,120.7417 133.68115,120.21777 C 133.35498,118.71484 133.02881,117.21191 132.70361,115.70898" id="path341" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.69287,99.543457 C 132.36768,101.04639 132.0415,102.54932 131.71631,104.05273 C 131.60303,104.57617 128.3042,105.13721 127.68994,105.31396 C 128.3042,105.49121 131.60303,106.05176 131.71631,106.57568 C 132.0415,108.07861 132.36768,109.58203 132.69287,111.08496 C 133.01904,109.58203 133.34521,108.07861 133.67139,106.57568 C 133.78467,106.05176 137.0835,105.49121 137.69678,105.31396 C 137.0835,105.13721 133.78467,104.57617 133.67139,104.05273 C 133.34521,102.54932 133.01904,101.04639 132.69287,99.543457" id="path343" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.72021,131.85547 C 132.39404,133.3584 132.06885,134.86133 131.74365,136.36426 C 131.62939,136.8877 128.33154,137.44922 127.71729,137.62598 C 128.33057,137.80273 131.62939,138.36426 131.74365,138.8877 C 132.06885,140.39063 132.39404,141.89355 132.72021,143.39746 C 133.04639,141.89355 133.37256,140.39063 133.69775,138.8877 C 133.81201,138.36426 137.11084,137.80273 137.72412,137.62598 C 137.11084,137.44922 133.81201,136.8877 133.69775,136.36426 C 133.37256,134.86133 133.04639,133.3584 132.72021,131.85547" id="path345" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.69385,115.70898 C 118.36865,117.21191 118.04248,118.71484 117.71631,120.21777 C 117.60303,120.7417 114.3042,121.30273 113.69092,121.47949 C 114.3042,121.65723 117.60303,122.21777 117.71631,122.74121 C 118.04248,124.24414 118.36865,125.74707 118.69385,127.25098 C 119.02002,125.74707 119.34521,124.24414 119.67139,122.74121 C 119.78467,122.21777 123.0835,121.65723 123.69678,121.47949 C 123.0835,121.30273 119.78467,120.7417 119.67139,120.21777 C 119.34521,118.71484 119.02002,117.21191 118.69385,115.70898" id="path347" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.68408,99.543457 C 118.35889,101.04639 118.03271,102.54932 117.70654,104.05273 C 117.59326,104.57617 114.29443,105.13721 113.68115,105.31396 C 114.29443,105.49121 117.59326,106.05176 117.70654,106.57568 C 118.03271,108.07861 118.35889,109.58203 118.68408,111.08496 C 119.01025,109.58203 119.33545,108.07861 119.66162,106.57568 C 119.7749,106.05176 123.07373,105.49121 123.68701,105.31396 C 123.07373,105.13721 119.7749,104.57617 119.66162,104.05273 C 119.33545,102.54932 119.01025,101.04639 118.68408,99.543457" id="path349" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.71045,131.85547 C 118.38525,133.3584 118.06006,134.86133 117.73389,136.36426 C 117.62061,136.8877 114.32178,137.44922 113.70752,137.62598 C 114.32178,137.80273 117.62061,138.36426 117.73389,138.8877 C 118.06006,140.39063 118.38525,141.89355 118.71045,143.39746 C 119.03662,141.89355 119.36279,140.39063 119.68896,138.8877 C 119.80225,138.36426 123.10107,137.80273 123.71436,137.62598 C 123.10107,137.44922 119.80225,136.8877 119.68896,136.36426 C 119.36279,134.86133 119.03662,133.3584 118.71045,131.85547" id="path351" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 90.675293,163.02832 C 90.350098,164.53125 90.024902,166.03418 89.69873,167.53809 C 89.585449,168.06152 86.286621,168.62207 85.67334,168.79883 C 86.286621,168.97559 89.585449,169.53711 89.69873,170.06152 C 90.024902,171.56348 90.350098,173.06738 90.675293,174.56934 C 91.001465,173.06738 91.327637,171.56348 91.653809,170.06152 C 91.76709,169.53711 95.065918,168.97559 95.679199,168.79883 C 95.065918,168.62207 91.76709,168.06152 91.653809,167.53809 C 91.327637,166.03418 91.001465,164.53125 90.675293,163.02832" id="path353" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 76.666504,163.02832 C 76.34082,164.53125 76.015137,166.03418 75.689453,167.53809 C 75.575684,168.06152 72.276855,168.62207 71.663086,168.79883 C 72.276855,168.97559 75.575684,169.53711 75.689453,170.06152 C 76.015137,171.56348 76.34082,173.06738 76.666504,174.56934 C 76.992676,173.06738 77.318359,171.56348 77.644043,170.06152 C 77.757813,169.53711 81.056641,168.97559 81.669922,168.79883 C 81.056641,168.62207 77.757812,168.06152 77.644043,167.53809 C 77.318359,166.03418 76.992676,164.53125 76.666504,163.02832" id="path355" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 104.67529,163.00098 C 104.34912,164.50488 104.02295,166.00781 103.69775,167.51074 C 103.58447,168.03418 100.28467,168.59473 99.671387,168.77246 C 100.28467,168.94922 103.58447,169.51074 103.69775,170.03418 C 104.02295,171.53711 104.34912,173.04004 104.67529,174.54297 C 105.00146,173.04004 105.32666,171.53711 105.65186,170.03418 C 105.76611,169.51074 109.06396,168.94922 109.67822,168.77246 C 109.06396,168.59473 105.76611,168.03418 105.65186,167.51074 C 105.32666,166.00781 105.00146,164.50488 104.67529,163.00098" id="path357" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 62.697266,163.04395 C 62.371582,164.54688 62.045898,166.0498 61.720215,167.55273 C 61.606934,168.07715 58.308105,168.6377 57.694336,168.81445 C 58.308105,168.99121 61.606934,169.55176 61.720215,170.07617 C 62.045898,171.5791 62.371582,173.08203 62.697266,174.58496 C 63.023438,173.08203 63.349121,171.5791 63.675293,170.07617 C 63.788574,169.55176 67.087402,168.99121 67.700683,168.81445 C 67.087402,168.6377 63.788574,168.07715 63.675293,167.55273 C 63.349121,166.0498 63.023437,164.54688 62.697266,163.04395" id="path359" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.67334,163.02441 C 132.34717,164.52832 132.02197,166.03027 131.69678,167.53418 C 131.58252,168.05762 128.28369,168.61816 127.66943,168.7959 C 128.28369,168.97168 131.58252,169.5332 131.69678,170.05762 C 132.02197,171.55957 132.34717,173.06348 132.67334,174.56543 C 132.99951,173.06348 133.32471,171.55957 133.65088,170.05762 C 133.76416,169.5332 137.06299,168.97168 137.67725,168.7959 C 137.06299,168.61816 133.76416,168.05762 133.65088,167.53418 C 133.32471,166.03027 132.99951,164.52832 132.67334,163.02441" id="path361" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.66357,163.02441 C 118.33838,164.52832 118.01221,166.03027 117.68701,167.53418 C 117.57275,168.05762 114.2749,168.61816 113.66064,168.7959 C 114.2749,168.97168 117.57275,169.5332 117.68701,170.05762 C 118.01221,171.55957 118.33838,173.06348 118.66357,174.56543 C 118.98975,173.06348 119.31592,171.55957 119.64209,170.05762 C 119.75537,169.5332 123.0542,168.97168 123.66748,168.7959 C 123.0542,168.61816 119.75537,168.05762 119.64209,167.53418 C 119.31592,166.03027 118.98975,164.52832 118.66357,163.02441" id="path363" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 90.675293,179.18652 C 90.350098,180.68945 90.024902,182.19238 89.69873,183.69531 C 89.585449,184.21875 86.286621,184.78027 85.67334,184.95703 C 86.286621,185.13379 89.585449,185.69434 89.69873,186.21875 C 90.024902,187.72168 90.350098,189.22461 90.675293,190.72754 C 91.001465,189.22461 91.327637,187.72168 91.653809,186.21875 C 91.76709,185.69434 95.065918,185.13379 95.679199,184.95703 C 95.065918,184.78027 91.76709,184.21875 91.653809,183.69531 C 91.327637,182.19238 91.001465,180.68945 90.675293,179.18652" id="path365" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 76.666504,179.18652 C 76.34082,180.68945 76.015137,182.19238 75.689453,183.69531 C 75.575684,184.21875 72.276855,184.78027 71.663086,184.95703 C 72.276855,185.13379 75.575684,185.69434 75.689453,186.21875 C 76.015137,187.72168 76.34082,189.22461 76.666504,190.72754 C 76.992676,189.22461 77.318359,187.72168 77.644043,186.21875 C 77.757813,185.69434 81.056641,185.13379 81.669922,184.95703 C 81.056641,184.78027 77.757812,184.21875 77.644043,183.69531 C 77.318359,182.19238 76.992676,180.68945 76.666504,179.18652" id="path367" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 104.67529,179.15918 C 104.34912,180.66211 104.02295,182.16504 103.69775,183.66895 C 103.58447,184.19238 100.28467,184.75293 99.671387,184.93066 C 100.28467,185.10645 103.58447,185.66797 103.69775,186.19238 C 104.02295,187.69434 104.34912,189.19824 104.67529,190.7002 C 105.00146,189.19824 105.32666,187.69434 105.65186,186.19238 C 105.76611,185.66797 109.06396,185.10645 109.67822,184.93066 C 109.06396,184.75293 105.76611,184.19238 105.65186,183.66895 C 105.32666,182.16504 105.00146,180.66211 104.67529,179.15918" id="path369" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 62.697266,179.20215 C 62.371582,180.70508 62.045898,182.20801 61.720215,183.71094 C 61.606934,184.23438 58.308105,184.7959 57.694336,184.97266 C 58.308105,185.14941 61.606934,185.70996 61.720215,186.23438 C 62.045898,187.7373 62.371582,189.24023 62.697266,190.74316 C 63.023438,189.24023 63.349121,187.7373 63.675293,186.23438 C 63.788574,185.70996 67.087402,185.14941 67.700683,184.97266 C 67.087402,184.7959 63.788574,184.23438 63.675293,183.71094 C 63.349121,182.20801 63.023437,180.70508 62.697266,179.20215" id="path371" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.67334,179.18262 C 132.34717,180.68555 132.02197,182.18848 131.69678,183.69141 C 131.58252,184.21582 128.28369,184.77637 127.66943,184.95313 C 128.28369,185.12988 131.58252,185.69141 131.69678,186.21484 C 132.02197,187.71777 132.34717,189.2207 132.67334,190.72363 C 132.99951,189.2207 133.32471,187.71777 133.65088,186.21484 C 133.76416,185.69141 137.06299,185.12988 137.67725,184.95313 C 137.06299,184.77637 133.76416,184.21582 133.65088,183.69141 C 133.32471,182.18848 132.99951,180.68555 132.67334,179.18262" id="path373" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.66357,179.18262 C 118.33838,180.68555 118.01221,182.18848 117.68701,183.69141 C 117.57275,184.21582 114.2749,184.77637 113.66064,184.95313 C 114.2749,185.12988 117.57275,185.69141 117.68701,186.21484 C 118.01221,187.71777 118.33838,189.2207 118.66357,190.72363 C 118.98975,189.2207 119.31592,187.71777 119.64209,186.21484 C 119.75537,185.69141 123.0542,185.12988 123.66748,184.95313 C 123.0542,184.77637 119.75537,184.21582 119.64209,183.69141 C 119.31592,182.18848 118.98975,180.68555 118.66357,179.18262" id="path375" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 90.675293,195.34473 C 90.350098,196.84668 90.024902,198.35059 89.69873,199.85352 C 89.585449,200.37695 86.286621,200.93848 85.67334,201.11426 C 86.286621,201.29199 89.585449,201.85254 89.69873,202.37598 C 90.024902,203.87988 90.350098,205.38184 90.675293,206.88574 C 91.001465,205.38184 91.327637,203.87988 91.653809,202.37598 C 91.76709,201.85254 95.065918,201.29199 95.679199,201.11426 C 95.065918,200.93848 91.76709,200.37695 91.653809,199.85352 C 91.327637,198.35059 91.001465,196.84668 90.675293,195.34473" id="path377" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 76.666504,195.34473 C 76.34082,196.84668 76.015137,198.35059 75.689453,199.85352 C 75.575684,200.37695 72.276855,200.93848 71.663086,201.11426 C 72.276855,201.29199 75.575684,201.85254 75.689453,202.37598 C 76.015137,203.87988 76.34082,205.38184 76.666504,206.88574 C 76.992676,205.38184 77.318359,203.87988 77.644043,202.37598 C 77.757813,201.85254 81.056641,201.29199 81.669922,201.11426 C 81.056641,200.93848 77.757812,200.37695 77.644043,199.85352 C 77.318359,198.35059 76.992676,196.84668 76.666504,195.34473" id="path379" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 104.67529,195.31738 C 104.34912,196.82031 104.02295,198.32324 103.69775,199.82715 C 103.58447,200.35059 100.28467,200.91113 99.671387,201.08789 C 100.28467,201.26465 103.58447,201.8252 103.69775,202.34961 C 104.02295,203.85254 104.34912,205.35547 104.67529,206.8584 C 105.00146,205.35547 105.32666,203.85254 105.65186,202.34961 C 105.76611,201.8252 109.06396,201.26465 109.67822,201.08789 C 109.06396,200.91113 105.76611,200.35059 105.65186,199.82715 C 105.32666,198.32324 105.00146,196.82031 104.67529,195.31738" id="path381" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 62.697266,195.36035 C 62.371582,196.8623 62.045898,198.36621 61.720215,199.86816 C 61.606934,200.3916 58.308105,200.95313 57.694336,201.12988 C 58.308105,201.30762 61.606934,201.86816 61.720215,202.3916 C 62.045898,203.89551 62.371582,205.39746 62.697266,206.90137 C 63.023438,205.39746 63.349121,203.89551 63.675293,202.3916 C 63.788574,201.86816 67.087402,201.30762 67.700683,201.12988 C 67.087402,200.95313 63.788574,200.3916 63.675293,199.86816 C 63.349121,198.36621 63.023437,196.8623 62.697266,195.36035" id="path383" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.67334,195.34082 C 132.34717,196.84277 132.02197,198.34668 131.69678,199.84961 C 131.58252,200.37305 128.28369,200.93457 127.66943,201.11133 C 128.28369,201.28809 131.58252,201.84863 131.69678,202.37207 C 132.02197,203.87598 132.34717,205.37793 132.67334,206.88184 C 132.99951,205.37793 133.32471,203.87598 133.65088,202.37207 C 133.76416,201.84863 137.06299,201.28809 137.67725,201.11133 C 137.06299,200.93457 133.76416,200.37305 133.65088,199.84961 C 133.32471,198.34668 132.99951,196.84277 132.67334,195.34082" id="path385" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.66357,195.34082 C 118.33838,196.84277 118.01221,198.34668 117.68701,199.84961 C 117.57275,200.37305 114.2749,200.93457 113.66064,201.11133 C 114.2749,201.28809 117.57275,201.84863 117.68701,202.37207 C 118.01221,203.87598 118.33838,205.37793 118.66357,206.88184 C 118.98975,205.37793 119.31592,203.87598 119.64209,202.37207 C 119.75537,201.84863 123.0542,201.28809 123.66748,201.11133 C 123.0542,200.93457 119.75537,200.37305 119.64209,199.84961 C 119.31592,198.34668 118.98975,196.84277 118.66357,195.34082" id="path387" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 90.675293,148.02441 C 90.350098,149.52832 90.024902,151.03027 89.69873,152.53418 C 89.585449,153.05762 86.286621,153.61816 85.67334,153.7959 C 86.286621,153.97266 89.585449,154.5332 89.69873,155.05762 C 90.024902,156.55957 90.350098,158.06348 90.675293,159.56543 C 91.001465,158.06348 91.327637,156.55957 91.653809,155.05762 C 91.76709,154.5332 95.065918,153.97168 95.679199,153.7959 C 95.065918,153.61816 91.76709,153.05762 91.653809,152.53418 C 91.327637,151.03027 91.001465,149.52832 90.675293,148.02441" id="path389" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 76.666504,148.02441 C 76.34082,149.52832 76.015137,151.03027 75.689453,152.53418 C 75.575684,153.05762 72.276855,153.61816 71.663086,153.7959 C 72.276855,153.97168 75.575684,154.5332 75.689453,155.05762 C 76.015137,156.55957 76.34082,158.06348 76.666504,159.56543 C 76.992676,158.06348 77.318359,156.55957 77.644043,155.05762 C 77.757813,154.5332 81.056641,153.97168 81.669922,153.7959 C 81.056641,153.61816 77.757812,153.05762 77.644043,152.53418 C 77.318359,151.03027 76.992676,149.52832 76.666504,148.02441" id="path391" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 104.67529,147.99805 C 104.34912,149.50098 104.02295,151.00391 103.69775,152.50684 C 103.58447,153.03027 100.28467,153.5918 99.671387,153.76855 C 100.28467,153.94531 103.58447,154.50684 103.69775,155.03027 C 104.02295,156.5332 104.34912,158.03613 104.67529,159.53906 C 105.00146,158.03613 105.32666,156.5332 105.65186,155.03027 C 105.76611,154.50684 109.06396,153.94531 109.67822,153.76855 C 109.06396,153.5918 105.76611,153.03027 105.65186,152.50684 C 105.32666,151.00391 105.00146,149.50098 104.67529,147.99805" id="path393" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 62.697266,148.04004 C 62.371582,149.54297 62.045898,151.0459 61.720215,152.5498 C 61.606934,153.07324 58.308105,153.63379 57.694336,153.81055 C 58.308105,153.9873 61.606934,154.54883 61.720215,155.07324 C 62.045898,156.5752 62.371582,158.0791 62.697266,159.58105 C 63.023438,158.0791 63.349121,156.5752 63.675293,155.07324 C 63.788574,154.54883 67.087402,153.9873 67.700683,153.81055 C 67.087402,153.63379 63.788574,153.07324 63.675293,152.5498 C 63.349121,151.0459 63.023437,149.54297 62.697266,148.04004" id="path395" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.67334,148.02051 C 132.34717,149.52441 132.02197,151.02637 131.69678,152.53027 C 131.58252,153.05371 128.28369,153.61426 127.66943,153.79199 C 128.28369,153.96875 131.58252,154.5293 131.69678,155.05371 C 132.02197,156.55566 132.34717,158.05957 132.67334,159.5625 C 132.99951,158.05957 133.32471,156.55566 133.65088,155.05371 C 133.76416,154.5293 137.06299,153.96875 137.67725,153.79199 C 137.06299,153.61426 133.76416,153.05371 133.65088,152.53027 C 133.32471,151.02637 132.99951,149.52441 132.67334,148.02051" id="path397" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.66357,148.02051 C 118.33838,149.52441 118.01221,151.02637 117.68701,152.53027 C 117.57275,153.05371 114.2749,153.61426 113.66064,153.79199 C 114.2749,153.96875 117.57275,154.5293 117.68701,155.05371 C 118.01221,156.55566 118.33838,158.05957 118.66357,159.5625 C 118.98975,158.05957 119.31592,156.55566 119.64209,155.05371 C 119.75537,154.5293 123.0542,153.96875 123.66748,153.79199 C 123.0542,153.61426 119.75537,153.05371 119.64209,152.53027 C 119.31592,151.02637 118.98975,149.52441 118.66357,148.02051" id="path399" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 76.666504,83.393555 C 76.34082,84.896484 76.015137,86.399414 75.689453,87.902344 C 75.575684,88.42627 72.276855,88.987305 71.663086,89.164063 C 72.276855,89.34082 75.575684,89.901856 75.689453,90.425781 C 76.015137,91.928711 76.34082,93.431641 76.666504,94.93457 C 76.992676,93.431641 77.318359,91.928711 77.644043,90.425781 C 77.757813,89.901856 81.056641,89.34082 81.669922,89.164063 C 81.056641,88.987305 77.757812,88.42627 77.644043,87.902344 C 77.318359,86.399414 76.992676,84.896484 76.666504,83.393555" id="path401" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 62.657227,83.393555 C 62.331543,84.896484 62.005859,86.399414 61.680176,87.902344 C 61.566895,88.42627 58.267578,88.987305 57.653809,89.164063 C 58.267578,89.34082 61.566894,89.901856 61.680176,90.425781 C 62.005859,91.928711 62.331543,93.431641 62.657227,94.93457 C 62.983399,93.431641 63.309082,91.928711 63.634766,90.425781 C 63.748535,89.901856 67.047363,89.34082 67.660645,89.164063 C 67.047363,88.987305 63.748535,88.42627 63.634766,87.902344 C 63.309082,86.399414 62.983398,84.896484 62.657227,83.393555" id="path403" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 104.68115,83.393555 C 104.35596,84.896484 104.03076,86.399414 103.70459,87.902344 C 103.59131,88.42627 100.29248,88.987305 99.678223,89.164063 C 100.29248,89.34082 103.59131,89.901856 103.70459,90.425781 C 104.03076,91.928711 104.35596,93.431641 104.68115,94.93457 C 105.00732,93.431641 105.3335,91.928711 105.65967,90.425781 C 105.77295,89.901856 109.07178,89.34082 109.68506,89.164063 C 109.07178,88.987305 105.77295,88.42627 105.65967,87.902344 C 105.3335,86.399414 105.00732,84.896484 104.68115,83.393555" id="path405" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 90.669434,83.393555 C 90.343262,84.896484 90.01709,86.399414 89.691895,87.902344 C 89.578613,88.42627 86.279785,88.987305 85.665527,89.164063 C 86.279785,89.34082 89.578613,89.901856 89.691895,90.425781 C 90.01709,91.928711 90.343262,93.431641 90.669434,94.93457 C 90.995606,93.431641 91.320801,91.928711 91.646973,90.425781 C 91.760254,89.901856 95.059082,89.34082 95.672363,89.164063 C 95.059082,88.987305 91.760254,88.42627 91.646973,87.902344 C 91.320801,86.399414 90.995606,84.896484 90.669434,83.393555" id="path407" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 62.664551,67.224121 C 62.338867,68.727051 62.012695,70.229981 61.687012,71.73291 C 61.573242,72.256836 58.274902,72.817871 57.661133,72.994629 C 58.274902,73.171875 61.57373,73.732422 61.687012,74.256348 C 62.012695,75.759277 62.338867,77.262207 62.664551,78.765625 C 62.990234,77.262207 63.315918,75.759277 63.64209,74.256348 C 63.755371,73.732422 67.054199,73.171875 67.66748,72.994629 C 67.054199,72.817871 63.755371,72.256836 63.64209,71.73291 C 63.315918,70.229981 62.990234,68.727051 62.664551,67.224121" id="path409" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 76.666504,67.235352 C 76.34082,68.738281 76.015137,70.241699 75.689453,71.744629 C 75.575684,72.268555 72.276855,72.829102 71.663086,73.006348 C 72.276855,73.183105 75.575684,73.744141 75.689453,74.268066 C 76.015137,75.770996 76.34082,77.273926 76.666504,78.776856 C 76.992676,77.273926 77.318359,75.770996 77.644043,74.268066 C 77.757813,73.744141 81.056641,73.183106 81.669922,73.006348 C 81.056641,72.829102 77.757812,72.268555 77.644043,71.744629 C 77.318359,70.241699 76.992676,68.738281 76.666504,67.235352" id="path411" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 90.675293,67.235352 C 90.350098,68.738281 90.024902,70.241699 89.69873,71.744629 C 89.585449,72.268555 86.286621,72.829102 85.67334,73.006348 C 86.286621,73.183105 89.585449,73.744141 89.69873,74.268066 C 90.024902,75.770996 90.350098,77.273926 90.675293,78.776856 C 91.001465,77.273926 91.327637,75.770996 91.653809,74.268066 C 91.76709,73.744141 95.065918,73.183106 95.679199,73.006348 C 95.065918,72.829102 91.76709,72.268555 91.653809,71.744629 C 91.327637,70.241699 91.001465,68.738281 90.675293,67.235352" id="path413" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 104.68506,67.235352 C 104.35889,68.738281 104.03271,70.241699 103.70752,71.744629 C 103.59424,72.268555 100.29541,72.829102 99.681152,73.006348 C 100.29541,73.183105 103.59424,73.744141 103.70752,74.268066 C 104.03271,75.770996 104.35889,77.273926 104.68506,78.776856 C 105.01123,77.273926 105.33643,75.770996 105.6626,74.268066 C 105.77588,73.744141 109.07471,73.183106 109.68896,73.006348 C 109.07471,72.829102 105.77588,72.268555 105.6626,71.744629 C 105.33643,70.241699 105.01123,68.738281 104.68506,67.235352" id="path415" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.68994,83.374023 C 132.36475,84.876953 132.03857,86.379883 131.7124,87.883301 C 131.59912,88.407226 128.30029,88.967773 127.68701,89.144531 C 128.30029,89.321777 131.59912,89.882812 131.7124,90.40625 C 132.03857,91.90918 132.36475,93.412109 132.68994,94.915527 C 133.01514,93.412109 133.34131,91.90918 133.66748,90.40625 C 133.78076,89.882812 137.07959,89.321777 137.69287,89.144531 C 137.07959,88.967773 133.78076,88.407226 133.66748,87.883301 C 133.34131,86.379883 133.01514,84.876953 132.68994,83.374023" id="path417" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.70361,67.235352 C 132.37744,68.738281 132.05225,70.241699 131.72607,71.744629 C 131.61279,72.268555 128.31396,72.829102 127.70068,73.006348 C 128.31396,73.183105 131.61279,73.744141 131.72607,74.268066 C 132.05225,75.770996 132.37744,77.273926 132.70361,78.776856 C 133.02881,77.273926 133.35498,75.770996 133.68115,74.268066 C 133.79443,73.744141 137.09326,73.183106 137.70654,73.006348 C 137.09326,72.829102 133.79443,72.268555 133.68115,71.744629 C 133.35498,70.241699 133.02881,68.738281 132.70361,67.235352" id="path419" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.68115,83.374023 C 118.35498,84.876953 118.02881,86.379883 117.70361,87.883301 C 117.59033,88.407226 114.2915,88.967773 113.67725,89.144531 C 114.2915,89.321777 117.59033,89.882812 117.70361,90.40625 C 118.02881,91.90918 118.35498,93.412109 118.68115,94.915527 C 119.00732,93.412109 119.33252,91.90918 119.65771,90.40625 C 119.77197,89.882812 123.0708,89.321777 123.68408,89.144531 C 123.0708,88.967773 119.77197,88.407226 119.65771,87.883301 C 119.33252,86.379883 119.00732,84.876953 118.68115,83.374023" id="path421" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.69385,67.235352 C 118.36865,68.738281 118.04248,70.241699 117.71631,71.744629 C 117.60303,72.268555 114.3042,72.829102 113.69092,73.006348 C 114.3042,73.183105 117.60303,73.744141 117.71631,74.268066 C 118.04248,75.770996 118.36865,77.273926 118.69385,78.776856 C 119.02002,77.273926 119.34521,75.770996 119.67139,74.268066 C 119.78467,73.744141 123.0835,73.183106 123.69678,73.006348 C 123.0835,72.829102 119.78467,72.268555 119.67139,71.744629 C 119.34521,70.241699 119.02002,68.738281 118.69385,67.235352" id="path423" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 76.666504,51.077637 C 76.34082,52.580566 76.015137,54.083496 75.689453,55.586914 C 75.575684,56.110352 72.276855,56.671387 71.663086,56.848633 C 72.276855,57.025391 75.575684,57.585938 75.689453,58.109863 C 76.015137,59.612793 76.34082,61.116211 76.666504,62.619141 C 76.992676,61.116211 77.318359,59.612793 77.644043,58.109863 C 77.757813,57.585938 81.056641,57.025391 81.669922,56.848633 C 81.056641,56.671387 77.757812,56.110352 77.644043,55.586914 C 77.318359,54.083496 76.992676,52.580566 76.666504,51.077637" id="path425" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 62.657227,51.077637 C 62.331543,52.580566 62.005859,54.083496 61.680176,55.586914 C 61.566895,56.110352 58.267578,56.671387 57.653809,56.848633 C 58.267578,57.025391 61.566894,57.585938 61.680176,58.109863 C 62.005859,59.612793 62.331543,61.116211 62.657227,62.619141 C 62.983399,61.116211 63.309082,59.612793 63.634766,58.109863 C 63.748535,57.585938 67.047363,57.025391 67.660645,56.848633 C 67.047363,56.671387 63.748535,56.110352 63.634766,55.586914 C 63.309082,54.083496 62.983398,52.580566 62.657227,51.077637" id="path427" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 104.68115,51.077637 C 104.35596,52.580566 104.03076,54.083496 103.70459,55.586914 C 103.59131,56.110352 100.29248,56.671387 99.678223,56.848633 C 100.29248,57.025391 103.59131,57.585938 103.70459,58.109863 C 104.03076,59.612793 104.35596,61.116211 104.68115,62.619141 C 105.00732,61.116211 105.3335,59.612793 105.65967,58.109863 C 105.77295,57.585938 109.07178,57.025391 109.68506,56.848633 C 109.07178,56.671387 105.77295,56.110352 105.65967,55.586914 C 105.3335,54.083496 105.00732,52.580566 104.68115,51.077637" id="path429" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 90.669434,51.077637 C 90.343262,52.580566 90.01709,54.083496 89.691895,55.586914 C 89.578613,56.110352 86.279785,56.671387 85.665527,56.848633 C 86.279785,57.025391 89.578613,57.585938 89.691895,58.109863 C 90.01709,59.612793 90.343262,61.116211 90.669434,62.619141 C 90.995606,61.116211 91.320801,59.612793 91.646973,58.109863 C 91.760254,57.585938 95.059082,57.025391 95.672363,56.848633 C 95.059082,56.671387 91.760254,56.110352 91.646973,55.586914 C 91.320801,54.083496 90.995606,52.580566 90.669434,51.077637" id="path431" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 62.664551,34.908203 C 62.338867,36.411133 62.012695,37.914063 61.687012,39.416992 C 61.57373,39.940918 58.274902,40.501953 57.661133,40.678711 C 58.274902,40.855957 61.573242,41.416992 61.687012,41.94043 C 62.012695,43.44336 62.338867,44.946289 62.664551,46.449219 C 62.990234,44.946289 63.315918,43.44336 63.64209,41.94043 C 63.755371,41.416992 67.054199,40.855957 67.66748,40.678711 C 67.054199,40.501953 63.755371,39.940918 63.64209,39.416992 C 63.315918,37.914063 62.990234,36.411133 62.664551,34.908203" id="path433" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 76.666504,34.919922 C 76.34082,36.422852 76.015137,37.925781 75.689453,39.428711 C 75.575684,39.952637 72.276855,40.513672 71.663086,40.69043 C 72.276855,40.867188 75.575684,41.428223 75.689453,41.952149 C 76.015137,43.455078 76.34082,44.958008 76.666504,46.460938 C 76.992676,44.958008 77.318359,43.455078 77.644043,41.952149 C 77.757813,41.428223 81.056641,40.867188 81.669922,40.69043 C 81.056641,40.513672 77.757812,39.952637 77.644043,39.428711 C 77.318359,37.925781 76.992676,36.422852 76.666504,34.919922" id="path435" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 90.675293,34.919922 C 90.350098,36.422852 90.024902,37.925781 89.69873,39.428711 C 89.585449,39.952637 86.286621,40.513672 85.67334,40.69043 C 86.286621,40.867188 89.585449,41.428223 89.69873,41.952149 C 90.024902,43.455078 90.350098,44.958008 90.675293,46.460938 C 91.001465,44.958008 91.327637,43.455078 91.653809,41.952149 C 91.76709,41.428223 95.065918,40.867188 95.679199,40.69043 C 95.065918,40.513672 91.76709,39.952637 91.653809,39.428711 C 91.327637,37.925781 91.001465,36.422852 90.675293,34.919922" id="path437" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 104.68506,34.919922 C 104.35889,36.422852 104.03271,37.925781 103.70752,39.428711 C 103.59424,39.952637 100.29541,40.513672 99.681152,40.69043 C 100.29541,40.867188 103.59424,41.428223 103.70752,41.952149 C 104.03271,43.455078 104.35889,44.958008 104.68506,46.460938 C 105.01123,44.958008 105.33643,43.455078 105.6626,41.952149 C 105.77588,41.428223 109.07471,40.867188 109.68896,40.69043 C 109.07471,40.513672 105.77588,39.952637 105.6626,39.428711 C 105.33643,37.925781 105.01123,36.422852 104.68506,34.919922" id="path439" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.68994,51.058594 C 132.36475,52.561524 132.03857,54.064453 131.7124,55.567383 C 131.59912,56.091309 128.30029,56.652344 127.68701,56.829102 C 128.30029,57.005859 131.59912,57.566895 131.7124,58.09082 C 132.03857,59.59375 132.36475,61.09668 132.68994,62.59961 C 133.01514,61.09668 133.34131,59.59375 133.66748,58.09082 C 133.78076,57.566895 137.07959,57.00586 137.69287,56.829102 C 137.07959,56.652344 133.78076,56.091309 133.66748,55.567383 C 133.34131,54.064453 133.01514,52.561524 132.68994,51.058594" id="path441" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 132.70361,34.919922 C 132.37744,36.422852 132.05225,37.925781 131.72607,39.428711 C 131.61279,39.952637 128.31396,40.513672 127.70068,40.69043 C 128.31396,40.867188 131.61279,41.428223 131.72607,41.952149 C 132.05225,43.455078 132.37744,44.958008 132.70361,46.460938 C 133.02881,44.958008 133.35498,43.455078 133.68115,41.952149 C 133.79443,41.428223 137.09326,40.867188 137.70654,40.69043 C 137.09326,40.513672 133.79443,39.952637 133.68115,39.428711 C 133.35498,37.925781 133.02881,36.422852 132.70361,34.919922" id="path443" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.68115,51.058594 C 118.35498,52.561524 118.02881,54.064453 117.70361,55.567383 C 117.59033,56.091309 114.2915,56.652344 113.67725,56.829102 C 114.2915,57.005859 117.59033,57.566895 117.70361,58.09082 C 118.02881,59.59375 118.35498,61.09668 118.68115,62.59961 C 119.00732,61.09668 119.33252,59.59375 119.65771,58.09082 C 119.77197,57.566895 123.0708,57.00586 123.68408,56.829102 C 123.0708,56.652344 119.77197,56.091309 119.65771,55.567383 C 119.33252,54.064453 119.00732,52.561524 118.68115,51.058594" id="path445" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 118.69385,34.919922 C 118.36865,36.422852 118.04248,37.925781 117.71631,39.428711 C 117.60303,39.952637 114.3042,40.513672 113.69092,40.69043 C 114.3042,40.867188 117.60303,41.428223 117.71631,41.952149 C 118.04248,43.455078 118.36865,44.958008 118.69385,46.460938 C 119.02002,44.958008 119.34521,43.455078 119.67139,41.952149 C 119.78467,41.428223 123.0835,40.867188 123.69678,40.69043 C 123.0835,40.513672 119.78467,39.952637 119.67139,39.428711 C 119.34521,37.925781 119.02002,36.422852 118.69385,34.919922" id="path447" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.628906,131.88184 C 34.303223,133.38574 33.977539,134.8877 33.651855,136.3916 C 33.538574,136.91504 30.239746,137.47559 29.625977,137.65332 C 30.239746,137.83008 33.538574,138.39063 33.651855,138.91504 C 33.977539,140.41699 34.303223,141.9209 34.628906,143.42383 C 34.955078,141.9209 35.280762,140.41699 35.606445,138.91504 C 35.720215,138.39063 39.019043,137.83008 39.632324,137.65332 C 39.019043,137.47559 35.720215,136.91504 35.606445,136.3916 C 35.280762,134.8877 34.955078,133.38574 34.628906,131.88184" id="path449" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.655762,115.73242 C 34.330078,117.23535 34.004394,118.73828 33.678711,120.24121 C 33.56543,120.76465 30.266602,121.3252 29.652832,121.50293 C 30.266601,121.67969 33.56543,122.24121 33.678711,122.76465 C 34.004394,124.26758 34.330078,125.77051 34.655762,127.27344 C 34.981934,125.77051 35.307617,124.26758 35.633789,122.76465 C 35.74707,122.24121 39.045898,121.67969 39.65918,121.50293 C 39.045898,121.3252 35.74707,120.76465 35.633789,120.24121 C 35.307617,118.73828 34.981933,117.23535 34.655762,115.73242" id="path451" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.672363,99.547363 C 34.34668,101.05029 34.020996,102.55371 33.695313,104.05664 C 33.581543,104.58008 30.283203,105.14111 29.669434,105.31836 C 30.283203,105.49512 33.581543,106.05615 33.695313,106.58008 C 34.020996,108.08301 34.34668,109.58594 34.672363,111.08887 C 34.998535,109.58594 35.324219,108.08301 35.650391,106.58008 C 35.763672,106.05615 39.0625,105.49512 39.675781,105.31836 C 39.0625,105.14111 35.763672,104.58008 35.650391,104.05664 C 35.324219,102.55371 34.998535,101.05029 34.672363,99.547363" id="path453" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.63916,163.02832 C 34.313477,164.53125 33.987793,166.03418 33.662109,167.53809 C 33.548828,168.06152 30.249512,168.62207 29.635742,168.79883 C 30.249512,168.97559 33.548828,169.53711 33.662109,170.06152 C 33.987793,171.56348 34.313477,173.06738 34.63916,174.56934 C 34.964844,173.06738 35.290527,171.56348 35.616699,170.06152 C 35.729981,169.53711 39.028809,168.97559 39.64209,168.79883 C 39.028809,168.62207 35.729981,168.06152 35.616699,167.53809 C 35.290527,166.03418 34.964844,164.53125 34.63916,163.02832" id="path455" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.63916,179.18652 C 34.313477,180.68945 33.987793,182.19238 33.662109,183.69531 C 33.548828,184.21875 30.249512,184.78027 29.635742,184.95703 C 30.249512,185.13379 33.548828,185.69434 33.662109,186.21875 C 33.987793,187.72168 34.313477,189.22461 34.63916,190.72754 C 34.964844,189.22461 35.290527,187.72168 35.616699,186.21875 C 35.729981,185.69434 39.028809,185.13379 39.64209,184.95703 C 39.028809,184.78027 35.729981,184.21875 35.616699,183.69531 C 35.290527,182.19238 34.964844,180.68945 34.63916,179.18652" id="path457" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.63916,195.34473 C 34.313477,196.84668 33.987793,198.35059 33.662109,199.85352 C 33.548828,200.37695 30.249512,200.93848 29.635742,201.11426 C 30.249512,201.29199 33.548828,201.85254 33.662109,202.37598 C 33.987793,203.87988 34.313477,205.38184 34.63916,206.88574 C 34.964844,205.38184 35.290527,203.87988 35.616699,202.37598 C 35.729981,201.85254 39.028809,201.29199 39.64209,201.11426 C 39.028809,200.93848 35.729981,200.37695 35.616699,199.85352 C 35.290527,198.35059 34.964844,196.84668 34.63916,195.34473" id="path459" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.63916,148.02441 C 34.313477,149.52832 33.987793,151.03027 33.662109,152.53418 C 33.548828,153.05762 30.249512,153.61816 29.635742,153.7959 C 30.249512,153.97168 33.548828,154.5332 33.662109,155.05762 C 33.987793,156.55957 34.313477,158.06348 34.63916,159.56543 C 34.964844,158.06348 35.290527,156.55957 35.616699,155.05762 C 35.729981,154.5332 39.028809,153.97266 39.64209,153.7959 C 39.028809,153.61816 35.729981,153.05762 35.616699,152.53418 C 35.290527,151.03027 34.964844,149.52832 34.63916,148.02441" id="path461" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.649414,83.389648 C 34.323731,84.892578 33.997559,86.395508 33.671875,87.898438 C 33.558594,88.422363 30.259766,88.983398 29.645996,89.160156 C 30.259766,89.336914 33.558105,89.898438 33.671875,90.421875 C 33.997559,91.924805 34.323731,93.427735 34.649414,94.930664 C 34.975098,93.427735 35.300781,91.924805 35.626953,90.421875 C 35.740235,89.898438 39.039063,89.336914 39.652832,89.160156 C 39.039063,88.983398 35.740234,88.422363 35.626953,87.898438 C 35.300781,86.395508 34.975098,84.892578 34.649414,83.389648" id="path463" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.63916,67.235352 C 34.313477,68.738281 33.987793,70.241699 33.662109,71.744629 C 33.548828,72.268555 30.249512,72.829102 29.635742,73.006348 C 30.249512,73.183105 33.548828,73.744141 33.662109,74.268066 C 33.987793,75.770996 34.313477,77.273926 34.63916,78.776856 C 34.964844,77.273926 35.290527,75.770996 35.616699,74.268066 C 35.729981,73.744141 39.028809,73.183106 39.64209,73.006348 C 39.028809,72.829102 35.729981,72.268555 35.616699,71.744629 C 35.290527,70.241699 34.964844,68.738281 34.63916,67.235352" id="path465" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.649414,51.073731 C 34.323731,52.577149 33.997559,54.080078 33.671875,55.583008 C 33.558594,56.106445 30.259766,56.667481 29.645996,56.844727 C 30.259766,57.021484 33.558594,57.58252 33.671875,58.106445 C 33.997559,59.609375 34.323731,61.112305 34.649414,62.615235 C 34.975098,61.112305 35.300781,59.609375 35.626953,58.106445 C 35.740235,57.58252 39.039063,57.021485 39.652832,56.844727 C 39.039063,56.667481 35.740234,56.106445 35.626953,55.583008 C 35.300781,54.080078 34.975098,52.577148 34.649414,51.073731" id="path467" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 34.63916,34.919922 C 34.313477,36.422852 33.987793,37.925781 33.662109,39.428711 C 33.548828,39.952637 30.249512,40.513672 29.635742,40.69043 C 30.249512,40.867188 33.548828,41.428223 33.662109,41.952149 C 33.987793,43.455078 34.313477,44.958008 34.63916,46.460938 C 34.964844,44.958008 35.290527,43.455078 35.616699,41.952149 C 35.729981,41.428223 39.028809,40.867188 39.64209,40.69043 C 39.028809,40.513672 35.729981,39.952637 35.616699,39.428711 C 35.290527,37.925781 34.964844,36.422852 34.63916,34.919922" id="path469" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.638184,131.88184 C 48.3125,133.38574 47.986816,134.8877 47.661133,136.3916 C 47.547363,136.91504 44.248535,137.47559 43.634766,137.65332 C 44.248535,137.83008 47.547363,138.39063 47.661133,138.91504 C 47.986816,140.41699 48.3125,141.9209 48.638184,143.42383 C 48.963867,141.9209 49.289551,140.41699 49.615723,138.91504 C 49.729004,138.39063 53.02832,137.83008 53.641602,137.65332 C 53.02832,137.47559 49.729004,136.91504 49.615723,136.3916 C 49.289551,134.8877 48.963867,133.38574 48.638184,131.88184" id="path471" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.665039,115.73242 C 48.339356,117.23535 48.013672,118.73828 47.687988,120.24121 C 47.574219,120.76465 44.275879,121.3252 43.662109,121.50293 C 44.275879,121.67969 47.574219,122.24121 47.687988,122.76465 C 48.013672,124.26758 48.339356,125.77051 48.665039,127.27344 C 48.991211,125.77051 49.316895,124.26758 49.642578,122.76465 C 49.756348,122.24121 53.055176,121.67969 53.668457,121.50293 C 53.055176,121.3252 49.756348,120.76465 49.642578,120.24121 C 49.316895,118.73828 48.991211,117.23535 48.665039,115.73242" id="path473" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.681641,99.547363 C 48.355957,101.05029 48.030273,102.55371 47.70459,104.05664 C 47.59082,104.58008 44.291992,105.14111 43.678223,105.31836 C 44.291992,105.49512 47.59082,106.05615 47.70459,106.58008 C 48.030273,108.08301 48.355957,109.58594 48.681641,111.08887 C 49.007324,109.58594 49.333496,108.08301 49.65918,106.58008 C 49.772949,106.05615 53.071289,105.49512 53.685059,105.31836 C 53.071289,105.14111 49.772949,104.58008 49.65918,104.05664 C 49.333496,102.55371 49.007324,101.05029 48.681641,99.547363" id="path475" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.648438,163.02832 C 48.322754,164.53125 47.996582,166.03418 47.670898,167.53809 C 47.557617,168.06152 44.258789,168.62207 43.645019,168.79883 C 44.258789,168.97559 47.557129,169.53711 47.670898,170.06152 C 47.996582,171.56348 48.322754,173.06738 48.648438,174.56934 C 48.974121,173.06738 49.299805,171.56348 49.625488,170.06152 C 49.739258,169.53711 53.038086,168.97559 53.651856,168.79883 C 53.038086,168.62207 49.739258,168.06152 49.625488,167.53809 C 49.299805,166.03418 48.974121,164.53125 48.648438,163.02832" id="path477" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.648438,179.18652 C 48.322754,180.68945 47.996582,182.19238 47.670898,183.69531 C 47.557617,184.21875 44.258789,184.78027 43.645019,184.95703 C 44.258789,185.13379 47.557617,185.69434 47.670898,186.21875 C 47.996582,187.72168 48.322754,189.22461 48.648438,190.72754 C 48.974121,189.22461 49.299805,187.72168 49.625488,186.21875 C 49.739258,185.69434 53.038086,185.13379 53.651856,184.95703 C 53.038086,184.78027 49.739258,184.21875 49.625488,183.69531 C 49.299805,182.19238 48.974121,180.68945 48.648438,179.18652" id="path479" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.648438,195.34473 C 48.322754,196.84668 47.996582,198.35059 47.670898,199.85352 C 47.557617,200.37695 44.258789,200.93848 43.645019,201.11426 C 44.258789,201.29199 47.557617,201.85254 47.670898,202.37598 C 47.996582,203.87988 48.322754,205.38184 48.648438,206.88574 C 48.974121,205.38184 49.299805,203.87988 49.625488,202.37598 C 49.739258,201.85254 53.038086,201.29199 53.651856,201.11426 C 53.038086,200.93848 49.739258,200.37695 49.625488,199.85352 C 49.299805,198.35059 48.974121,196.84668 48.648438,195.34473" id="path481" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.648438,148.02441 C 48.322754,149.52832 47.996582,151.03027 47.670898,152.53418 C 47.557617,153.05762 44.258789,153.61816 43.645019,153.7959 C 44.258789,153.97266 47.557617,154.5332 47.670898,155.05762 C 47.996582,156.55957 48.322754,158.06348 48.648438,159.56543 C 48.974121,158.06348 49.299805,156.55957 49.625488,155.05762 C 49.739258,154.5332 53.038086,153.97168 53.651856,153.7959 C 53.038086,153.61816 49.739258,153.05762 49.625488,152.53418 C 49.299805,151.03027 48.974121,149.52832 48.648438,148.02441" id="path483" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.658203,83.389648 C 48.33252,84.892578 48.006836,86.395508 47.681152,87.898438 C 47.567871,88.422363 44.269043,88.983398 43.655273,89.160156 C 44.269043,89.336914 47.567871,89.898438 47.681152,90.421875 C 48.006836,91.924805 48.33252,93.427735 48.658203,94.930664 C 48.984375,93.427735 49.310059,91.924805 49.635742,90.421875 C 49.749512,89.898438 53.04834,89.336914 53.661621,89.160156 C 53.04834,88.983398 49.749512,88.422363 49.635742,87.898438 C 49.310059,86.395508 48.984375,84.892578 48.658203,83.389648" id="path485" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.648438,67.235352 C 48.322754,68.738281 47.996582,70.241699 47.670898,71.744629 C 47.557617,72.268555 44.258789,72.829102 43.645019,73.006348 C 44.258789,73.183105 47.557617,73.744141 47.670898,74.268066 C 47.996582,75.770996 48.322754,77.273926 48.648438,78.776856 C 48.974121,77.273926 49.299805,75.770996 49.625488,74.268066 C 49.739258,73.744141 53.038086,73.183106 53.651856,73.006348 C 53.038086,72.829102 49.739258,72.268555 49.625488,71.744629 C 49.299805,70.241699 48.974121,68.738281 48.648438,67.235352" id="path487" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.658203,51.073731 C 48.33252,52.577149 48.006836,54.080078 47.681152,55.583008 C 47.567871,56.106445 44.269043,56.667481 43.655273,56.844727 C 44.269043,57.021484 47.567871,57.58252 47.681152,58.106445 C 48.006836,59.609375 48.33252,61.112305 48.658203,62.615235 C 48.984375,61.112305 49.310059,59.609375 49.635742,58.106445 C 49.749512,57.58252 53.04834,57.021485 53.661621,56.844727 C 53.04834,56.667481 49.749512,56.106445 49.635742,55.583008 C 49.310059,54.080078 48.984375,52.577148 48.658203,51.073731" id="path489" />
      <path style={{ fill: '#ffffff', fillRule: 'evenodd', stroke: '#ffffff', strokeWidth: '0.02682629', strokeMiterlimit: 10 }} d="M 48.648438,34.919922 C 48.322754,36.422852 47.996582,37.925781 47.670898,39.428711 C 47.557617,39.952637 44.258789,40.513672 43.645019,40.69043 C 44.258789,40.867188 47.557617,41.428223 47.670898,41.952149 C 47.996582,43.455078 48.322754,44.958008 48.648438,46.460938 C 48.974121,44.958008 49.299805,43.455078 49.625488,41.952149 C 49.739258,41.428223 53.038086,40.867188 53.651856,40.69043 C 53.038086,40.513672 49.739258,39.952637 49.625488,39.428711 C 49.299805,37.925781 48.974121,36.422852 48.648438,34.919922" id="path491" />
      <path style={{ fill: 'none', stroke: '#fb0f0c', strokeWidth: '1.28766251', strokeMiterlimit: 10 }} d="M 8.3720703,6.6435547 C 6.0195312,6.6435547 6.8710937,13.0625 6.8710937,14.344727 C 6.8710937,20.206543 6.8710937,26.068359 6.8710937,31.930176 C 6.8710937,51.31543 6.8710937,70.701172 6.8710937,90.086426 C 6.8710937,131.64355 6.8710937,173.2002 6.8710937,214.75684 C 6.8710937,219.92285 6.8710937,225.08887 6.8710937,230.25293 C 6.8710937,232.00488 6.2050781,235.73926 8.7954101,235.73926 C 22.132812,235.73926 35.469727,235.73926 48.807129,235.73926 C 85.208496,235.73926 121.60889,235.73926 158.00928,235.73926 C 160.75732,235.73926 160.22119,232.80273 160.22119,230.82324 C 160.22119,225.875 160.22119,220.92676 160.22119,215.97754 C 160.22119,197.64355 160.22119,179.30762 160.22119,160.97266 C 160.22119,118.41211 160.22119,75.851074 160.22119,33.290039 C 160.22119,27.227539 160.22119,21.164063 160.22119,15.101074 C 160.22119,13.058106 160.68896,10.382813 160.22119,8.3750002 C 160.22119,5.3969729 151.76416,6.6435549 150.09717,6.6435549 C 142.12646,6.6435549 134.15674,6.6435549 126.18701,6.6435549 C 87.837402,6.6435549 49.487305,6.6435549 11.137695,6.6435549 C 10.21582,6.6435549 9.2939453,6.6435549 8.3720703,6.6435549" id="path493" />
    </SvgIcon>
  )
}

export default CardBack;