import React, { useState } from 'react';
import { Grid, Card, TextField, Button } from "@mui/material";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setPageTitle } from '../../redux/app';
import { setAudio } from '../../redux/worldbook';
import { v4 as uuidV4 } from 'uuid'



const WorldbookPageEdit = ({ currentPage, setEdit, adminEdit }) => {
  const dispatch = useDispatch();

  const uuidParagraphs = currentPage?.paragraphs?.map((para) => ({ ...para, index: para.index || uuidV4() })) || []

  const [paragraphs, setParagraphs] = useState(uuidParagraphs)
  const [newTitle, setNewTitle] = useState(currentPage.title)
  const [newPerms, setNewPerms] = useState(currentPage.hidden || '')

  useEffect(() => {
    if (currentPage.title === 'Worldbook') {
      dispatch(setPageTitle('Worldbook'));
    } else {
      dispatch(setPageTitle(`Worldbook: ${currentPage.title}`));
    }
    if (currentPage.pronunciation) dispatch(setAudio(currentPage.pronunciation))
  }, [currentPage.pronunciation, currentPage.title, dispatch])

  const addParagraph = () => {
    setParagraphs([...paragraphs, { title: '', text: '', index: uuidV4() }])
  }

  const deleteParagraph = (index) => {
    const newParagraphs = [...paragraphs]
    newParagraphs.splice(index, 1)
    setParagraphs(newParagraphs)
  }

  const setTitle = (e) => {
    setNewTitle(e.target.value)
  }

  const getFormData = () => {
    let formDataArray = [...new FormData(document.getElementById('editform'))]
    let array = []
    let obj = {}
    for (let i = 0; i < formDataArray.length; i += 1) {
      if (i % 2 === 0) {
        obj = { ...paragraphs[Math.floor(i / 2)] }
        obj.title = formDataArray[i][1]
      } else {
        obj.text = formDataArray[i][1]
        if (!obj.index) obj.index = uuidV4();
        array.push(obj)
      }
    }
    return array;
  }
  return (
    <Card sx={{ margin: '12px', padding: '12px', width: '100%' }}>
      <Grid>
        <Grid container justifyContent="end">
          <TextField
            onChange={setTitle}
            value={newTitle}
            sx={{
              width: '100%',
              marginBottom: '8px'
            }}
            InputProps={{
              sx: {
                fontSize: '28px'
              }
            }}
          />
          { adminEdit && <TextField
            onChange={(e) => setNewPerms(e.target.value)}
            value={newPerms}
            sx={{
              width: '20%',
              alignSelf: 'end',
              marginBottom: '24px'
            }}
            inputProps={{
              sx: {
                padding: '8px 16px'
              }
            }}
          />
          }
        </Grid>
        <form id="editform">
          {paragraphs &&
            paragraphs.map((paragraph, textIndex) => {
              return (
                <Grid container key={`para-${paragraph.index}`} sx={paragraph.gridSx}>
                  {paragraph.image && <img style={paragraph.image.sx} src={paragraph.image.src} alt={paragraph.image.alt}></img>}
                  <Grid container direction="column" sx={{ gap: '8px' }}>
                    <Grid>

                      <TextField name={`title-${textIndex}`} defaultValue={paragraph.title || ''} inputProps={{
                        sx: {
                          fontSize: '20px',
                          fontWeight: 'bold',
                          padding: '4px 10px'
                        }
                      }} />
                      <Button variant="outlined" color="error" onClick={() => deleteParagraph(textIndex)}><Delete /></Button>
                    </Grid>
                    <TextField name={`text-${textIndex}`} multiline sx={{ paddingBottom: '16px' }} defaultValue={paragraph.text} />
                  </Grid>
                </Grid>
              )
            })
          }

        </form>
      </Grid>
      <Grid container justifyContent="space-between">
        {/* Span element here to be an empty space to keep new paragraph button centered*/}
        <Button variant="outlined" onClick={() => setEdit(false)}>Cancel</Button>
        <Button variant="outlined" onClick={addParagraph}><AddCircleOutline /></Button>
        <Button variant="contained" onClick={() => setEdit(getFormData(), newTitle, newPerms)}>SAVE</Button>
      </Grid>
    </Card >

  );
}

export default WorldbookPageEdit;
