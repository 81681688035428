import { Card, Typography, Grid } from "@mui/material";
import SuitIcon from './SuitIcon';

const PlayingCard = ({ suit, value }) => {
  const textColor = (['Hearts', 'Diamonds'].includes(suit) ? 'red' : 'black')
  const textStyle = { fontSize: '18px', fontWeight: '700', paddingTop: '4px', paddingLeft: '4px' }
  return (
    <Card sx={{ backgroundColor: 'white', color: textColor, width: '80px', height: '120px' }}>
      <Grid container direction="column">
        <Typography sx={textStyle}>{value}</Typography>
        <SuitIcon sx={{ fontSize: '60px', alignSelf: 'center' }} suit={suit} />
        <Typography sx={{ ...textStyle, transform: 'rotate(180deg)' }}>{value}</Typography>
      </Grid>
    </Card>
  )
}

export default PlayingCard;