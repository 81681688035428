import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CustomLink = ({to, children, underlined = false}) => {
  const theme = useSelector((state) => state.app.theme)
  const color = (theme === 'dark') ? 'white' : 'darkblue';
  const textDecoration = (underlined) ? 'underline' : 'none'
  return (
    <Link style={{color, textDecoration}} to={to}>{children}</Link>
  )
}

export default CustomLink;