import jwtDecode from "jwt-decode";
import { urlBuild } from "../utils/config.js";

const getHeaders = (post = false) => {
  let headers = {};
  let token = localStorage.getItem("token");
  try {
    const decodedToken = jwtDecode(token);
    if (parseInt(Date.now().toString().substr(0, 10)) > decodedToken.exp) {
      localStorage.removeItem("token");
      token = false;
    }
  } catch (e) {
    localStorage.removeItem("token");
    token = false;
  }
  if (token)
    headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  if (post) headers["Content-Type"] = "application/json";
  return headers;
};

export const fetchAllGames = async () => {
  try {
    const gamesResponse = await fetch(urlBuild("allGames"), {
      headers: getHeaders(),
    });
    if (gamesResponse.ok) {
      const gamesJson = gamesResponse.json();
      return gamesJson;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateGames = async (body) => {
  try {
    const gamesResponse = await fetch(urlBuild("updateGames"), {
      method: "POST",
      body,
      headers: getHeaders(true),
    });
    if (gamesResponse.ok) {
      return true;
    }
    return false;
  } catch (e) {
    console.error("Failed to update games");
    return false;
  }
};

export const fetchThingDetails = async (thingId) => {
  try {
    const thingResponse = await fetch(urlBuild("thing", { thingId }), {
      headers: getHeaders(),
    });
    if (thingResponse.ok) {
      const thingJson = thingResponse.json();
      return thingJson;
    }
    return false;
  } catch (e) {
    console.error("Failed to fetch thing data");
    return false;
  }
};

export const fetchWorldbookData = async () => {
  try {
    let worldbookUrl = urlBuild("worldbook");
    const adminKey = localStorage.getItem("adminKey");
    if (adminKey) worldbookUrl += `?adminKey=${adminKey}`;
    const worldbookResponse = await fetch(worldbookUrl, {
      headers: getHeaders(),
    });
    if (worldbookResponse.ok) {
      return await worldbookResponse.json();
    }
    return false;
  } catch (e) {
    console.error("Failed to fetch worldbook data");
    return false;
  }
};

export const updatehWorldbookData = async (data) => {
  try {
    let worldbookUrl = urlBuild("worldbook");
    const worldbookResponse = await fetch(worldbookUrl, {
      method: "POST",
      headers: getHeaders(true),
      body: JSON.stringify(data),
    });
    if (worldbookResponse.ok) {
      return true;
    }
    return false;
  } catch (e) {
    console.error("Failed to update worldbook data");
    return false;
  }
};

export const calculatePokerCard = async (pokerCards) => {
  try {
    const pokerUrl = urlBuild("poker", pokerCards);
    const pokerResponse = await fetch(pokerUrl, { headers: getHeaders() });
    if (pokerResponse.ok) {
      return await pokerResponse.json();
    }
    return false;
  } catch (e) {
    console.error("Failed to calculate poker card");
    return false;
  }
};

export const getQotdQuestion = async (date) => {
  try {
    const qotdUrl = urlBuild("qotd", { qora: "question", date });
    const qotdResponse = await fetch(qotdUrl, { headers: getHeaders() });
    if (qotdResponse.ok) {
      return await qotdResponse.json();
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getQotdAnswers = async (date) => {
  try {
    const qotdUrl = urlBuild("qotd", { qora: "answers", date });
    const qotdResponse = await fetch(qotdUrl, { headers: getHeaders() });
    if (qotdResponse.ok) {
      return await qotdResponse.json();
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const startScrape = async (
  auth,
  channel,
  includeMessages,
  includeCalls,
  combined
) => {
  try {
    const gamesResponse = await fetch(urlBuild("scrape"), {
      method: "POST",
      headers: getHeaders(true),
      body: JSON.stringify({
        auth,
        channel,
        includeMessages,
        includeCalls,
        combined,
      }),
    });
    if (gamesResponse.ok) {
      const gamesJson = gamesResponse.json();
      return gamesJson;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const checkScrape = async (accessKey) => {
  try {
    const gamesResponse = await fetch(urlBuild("download", { accessKey }), {
      headers: getHeaders(),
    });
    const gamesJson = gamesResponse.json();
    return gamesJson;
  } catch (e) {
    return {};
  }
};
