import { createStore } from 'redux'
import { combineReducers } from 'redux'
import appReducer from './app';
import boardgameDialogReducer from './boardgameDialog';
import DiceRollerReducer from './diceRoller';
import worldbookReducer from './worldbook';

const combinedReducers = combineReducers({
  app: appReducer,
  boardgameDialog: boardgameDialogReducer,
  dice: DiceRollerReducer,
  worldbook: worldbookReducer,
})

export default createStore(combinedReducers)