import React from 'react';
import { Typography, Grid, Card, TextField, Button } from "@mui/material";
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setPageTitle } from '../../redux/app';
import AudioButton from '../common/AudioButton';
import { setAudio } from '../../redux/worldbook';
import { useState } from 'react';



const CreateNewPage = ({ currentPage, newPageSubpath, handleSave }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');

  useEffect(() => {
    if (currentPage.title === 'Worldbook') {
      dispatch(setPageTitle('Worldbook'));
    } else {
      dispatch(setPageTitle(`Worldbook: ${currentPage.title}`));
    }
    if (currentPage.pronunciation) dispatch(setAudio(currentPage.pronunciation))
  }, [currentPage.pronunciation, currentPage.title, dispatch])

  const updateText = (e) => {
    setText(e.target.value)
  }

  const createPage = () => {
    const newPage = {
      title: text,
      paragraphs: []
    }
    const newCurrentPage = JSON.parse(JSON.stringify(currentPage), false)
    newCurrentPage.children[newPageSubpath] = newPage
    handleSave(newCurrentPage)
  }

  return (
    <Card sx={{ margin: '12px', padding: '12px', width: '100%' }}>
      <Grid>
        <Grid container>
          <Typography variant="h3">This page does not currently exist</Typography>
          {currentPage.pronunciation && <AudioButton srcFile={currentPage.pronunciation} />}
        </Grid>
        <Typography variant="h5">Create Page?</Typography>
        <Grid>
          <TextField onChange={updateText}>{text}</TextField>
          <Button
            disabled={text.trim().length === 0}
            onClick={createPage}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </Card >

  );
}

export default CreateNewPage;
