import { Card, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { goto } from "../../redux/app";
import { useLocation } from "react-router-dom";
import Link from "../common/Link";



const WorldbookNavigation = ({ children, hide }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleNavigation = (event, path) => {
    dispatch(goto({ path: location.pathname + "/" + path, title: 'Worldbook' }))
  }
  if (!children) return null;
  if (!Object.keys(children).length) return null;
  const editStyles = hide ? {opacity: 0, pointerEvents: 'none'} : {}
  return (
    <Card sx={{ width: '150px', margin: '12px', padding: '12px', height: 'fit-content', ...editStyles }}>
      {
        Object.keys(children).map((child) => {
          return (<Typography key={`${children[child].title || child}nav`}>
            <Link underlined to={location.pathname + "/" + child} onClick={(event) => { handleNavigation(event, child) }}>
              {children[child].menuText ||children[child].title || child}
            </Link>
          </Typography>
          )
        })
      }
    </Card>
  );
}

export default WorldbookNavigation;
