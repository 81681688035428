import { Grid, Card, CardHeader, CardContent } from "@mui/material"

const Stud = () => {
  return (
    <Grid container justifyContent="center">
      <Card sx={{ width: '65%', margin: '16px' }}>
        <CardHeader title="Total Stud" />
        <CardContent>
          <img style={{ width: '100%' }} src='/images/stud.jpg' alt="total stud"></img>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default Stud;