
import React, { useEffect, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import Home from '../home';
import About from '../about';
import Boardgames from '../boardgames';
import BoardgamesGrid from '../boardgamesGrid';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import NavigateListener from './NavigateListener';
import DiceRoller from '../diceRoller';
import GoDice from '../goDice';
import Navigation from './Navigation';
import Worldbook from '../worldbook';
import PokerCalculator from '../poker';
import Stud from '../poker/Stud';
import { setUser } from "../../redux/app";
import jwtDecode from "jwt-decode";
import QOTD from "../qotd";
import Discord from "../discord";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const themes = {
  dark: darkTheme,
  light: lightTheme,
}

const Themes = () => {
  const theme = useSelector(state => state.app.theme);
  const dispatch = useDispatch();
  const [doneAuth, setDoneAuth] = useState(false);

  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "profdecube.eu.auth0.com";
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user"
        });
        localStorage.setItem('token', accessToken)
        dispatch(setUser(jwtDecode(accessToken)))
        setDoneAuth(true);
      } catch (e) {
        localStorage.removeItem('token')
        if(e.message !== 'Login required') console.error(e.message);
        setDoneAuth(true);
      }
    };

    getUserMetadata();
  }, [dispatch, getAccessTokenSilently, isAuthenticated, isLoading]);

  return (
    <Auth0Provider
      domain="profdecube.eu.auth0.com"
      clientId="cIzKjgTtGH9lxqDoBa7j5qzItayLrInz"
      redirectUri={window.location.origin}
      audience="https://profdecube.eu.auth0.com/api/v2/"
      scope="read:current_user update:current_user_metadata roles Roles"
    >
      <Router>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes[theme]}>
            <Header />
            <Navigation />
            {doneAuth && <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/boardgames/grid">
                <BoardgamesGrid />
              </Route>
              <Route path="/boardgames">
                <Boardgames />
              </Route>
              <Route path="/dice">
                <DiceRoller />
              </Route>
              <Route path="/godice">
                <GoDice />
              </Route>
              <Route path="/newworldbook">
                <Worldbook />
              </Route>
              <Route path="/poker">
                <PokerCalculator />
              </Route>
              <Route path="/stud">
                <Stud />
              </Route>
              <Route path="/qotd">
                <QOTD />
              </Route>
              <Route path="/discord">
                <Discord />
              </Route>
            </Switch>
            }
            <CssBaseline />
            <NavigateListener />
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </Auth0Provider>
  );
}

export default Themes;
