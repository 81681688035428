import { Route, Switch } from "react-router-dom";
import Question from './Question';
import Answer from './Answer';

const QOTD = () => {

  return (
    <Switch>
      <Route exact path="/qotd/answer">
        <Answer />
      </Route>
      <Route exact path="/qotd">
        <Question />
      </Route>
    </Switch>
  );
}

export default QOTD;
