import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, IconButton } from '@mui/material';
import { useRef } from 'react';
import { DarkMode, DarkModeOutlined, AccountCircle, Logout, Login, AccountCircleOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from "../../redux/app";
import { useEffect } from 'react';

const UserMenu = () => {

  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const theme = useSelector(state => state.app.theme);
  const toggleDarkMode = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    if (theme !== newTheme) dispatch(setTheme(newTheme));
  }

  useEffect(() => {
    setOpen(false)
  }, [isAuthenticated])

  const buttonIcon = theme === 'dark' ? <DarkMode fontSize="small" /> : <DarkModeOutlined fontSize="small" />
  const anchorEl = useRef(null)

  return (
    <>
      <IconButton onClick={() => setOpen(true)} ref={anchorEl}>
        {isAuthenticated ? <AccountCircle /> : <AccountCircleOutlined />}
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
      >
        {isAuthenticated && <MenuItem><Typography align="center">{user.name.split(" ")[0]}</Typography>
          <Divider /></MenuItem>}
        <MenuList>
            <MenuItem onClick={toggleDarkMode}>
              <ListItemIcon>
                {buttonIcon}
              </ListItemIcon>
              <ListItemText>Theme</ListItemText>
            </MenuItem>
            {!isAuthenticated &&
              <MenuItem onClick={() => {localStorage.removeItem('token'); loginWithRedirect()}}>
                <ListItemIcon>
                  <Login fontSize="small" />
                </ListItemIcon>
                <ListItemText>Login</ListItemText>
              </MenuItem>
            }

            {isAuthenticated &&
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            }

          </MenuList>

      </Menu>
    </>
  );
}

export default UserMenu;
