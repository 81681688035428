import { createSlice } from '@reduxjs/toolkit'
import { reseed, createSeed } from '../utils/random';

const initialState = {
  dice: 'D6',
  count: 4,
  set: false,
  rolls: [],
  seed: '',
  maxRolls: '6',
  dropKeep: false,
  showTotal: false,
}
Math.randomBackup = Math.random
reseed(initialState.dice + initialState.count + 'James')

export const diceSlice = createSlice({
  name: 'diceRoller',
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.dice = action.payload.dice;
      state.count = action.payload.count;
      state.maxRolls = action.payload.maxRolls;
      state.set = true;
      if(action.payload.seed) {
        reseed(action.payload.dice + action.payload.count + action.payload.maxRolls + action.payload.seed);
        state.seed = action.payload.seed;
      } else {
        const seed = createSeed(8);
        state.seed = seed;
        reseed(action.payload.dice + action.payload.count + action.payload.maxRolls + seed);
      }
    },
    addRolls: (state, action) => {
      state.rolls.push(action.payload)
    },
    resetRolls: (state) => {
      state.rolls = [];
      state.set = false;
      state.seed = '';
    }
  },
})

// Action creators are generated for each case reducer function
export const { setValues, addRolls, resetRolls } = diceSlice.actions

export default diceSlice.reducer