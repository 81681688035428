import { Button, Card, Grid, Typography, CardHeader, CardContent } from "@mui/material";
import { useState } from "react";
import EditablePlayingCard from "./EditablePlayingCard";
import PlayingCard from './PlayingCard';
import PlayingCardRandom from './PlayingCardRandom'
import CardBack from './CardBack';
import { calculatePokerCard } from "../../service/backendService";
import { wait } from "../../utils/wait";
import { randomInt } from "../../utils/random";

const PokerCalculator = () => {
  let getNewCard = false
  const cardBackColours = ['red', 'navy', 'black', 'darkgreen', 'pink', 'orange', 'blueviolet', 'cadetblue', 'crimson', 'goldenrod']
  const [faceDownCard, setFaceDownCard] = useState({});
  const [cardBackColour, setCardBackColour] = useState(cardBackColours[randomInt(0, cardBackColours.length - 1)])
  const [calculating, setCalculating] = useState(false);
  const [revealed, setRevealed] = useState(false)
  const [cardArray, setCardArray] = useState([]);
  const logCard = (getCardFunc) => {
    getNewCard = getCardFunc
  }

  const handleNewCardClick = () => {
    const newCard = getNewCard();
    let presentInCardArray = false;
    for (let card of cardArray) {
      if (card.suit === newCard.suit && card.value === newCard.value) {
        presentInCardArray = true;
        break;
      }
    }
    if (newCard.value !== '' && newCard.value !== '1' && !presentInCardArray) {
      setCardArray([...cardArray, newCard])
    }
  }

  const handleCalculate = async () => {
    setCalculating(true)
    const card1 = (cardArray[0].value + cardArray[0].suit[0]).replace('10', 'X');
    const card2 = (cardArray[1].value + cardArray[1].suit[0]).replace('10', 'X');
    const card3 = (cardArray[2].value + cardArray[2].suit[0]).replace('10', 'X');
    const card4 = (cardArray[3].value + cardArray[3].suit[0]).replace('10', 'X');
    const faceDownCalculated = await calculatePokerCard({ card1, card2, card3, card4 })
    await wait(5000);

    setCalculating(false)
    setFaceDownCard(faceDownCalculated)
  };

  const doReset = () => {
    setFaceDownCard({});
    setRevealed(false);
    setCardArray([]);
    setCalculating(false)
    setCardBackColour(cardBackColours[randomInt(0, cardBackColours.length - 1)])
  }

  return (
    <Grid container justifyContent="center">
      <Card sx={{ maxWidth: '700px', margin: '12px', padding: '12px' }} elevation={2}>
        <CardHeader title="Stud Poker Calculator" />
        <CardContent>
          <Typography>Stud poker is a game played with 5 cards, where 4 are known and the fifth is a secret. It is rarely played in the modern day due to the fact that once 4 cards are revealed, the fifth can be determined with 95% accuracy. Using statistical analysis you can work out the final card in anyone's hand using just the four you are aware of. It is however a complex calculation so this tool handles all of that for you.</Typography>
          <Grid container gap="4px" sx={{ margin: '12px' }}>
            <Grid sx={{ margin: '0 20px' }}>
              {
                revealed ?
                  <PlayingCard suit={faceDownCard.suit} value={faceDownCard.value} /> :
                  (calculating ? <PlayingCardRandom /> : <CardBack backColour={cardBackColour} />)
              }
            </Grid>
            {cardArray.map((card, cardIndex) => <PlayingCard key={`card-${cardIndex}`} suit={card.suit} value={card.value} />)}
            {(cardArray.length < 4) && <EditablePlayingCard getCardDetails={logCard} changeListener={cardArray.length} />}
          </Grid>
          <Grid container gap="4px" justifyContent="space-between">
            <Button color="error" onClick={doReset}>Reset</Button>
            {(cardArray.length < 4) && <Button disabled={getNewCard} onClick={handleNewCardClick}>Add Card</Button>}
            {(cardArray.length >= 4 && !faceDownCard.value) && <Button disabled={calculating} onClick={handleCalculate} >{calculating ? 'Calculating...' : 'Calculate'}</Button>}
            {faceDownCard.value && <Button onClick={() => setRevealed(true)} >Reveal</Button>}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PokerCalculator;