import { useState } from 'react'
import {
  Box, Grid, Typography
} from "@mui/material";

const sortRectsIntoColumns = (columns, rects) => {
  const leeway = 55
  const columnHeights = new Array(columns).fill(0)
  const rectColumns = new Array(columns).fill(0).map(() => [])
  for (const rectI in rects) {
    const rect = rects[rectI]

    const currentColumnHeights = [...columnHeights].sort((a, b) => a - b);
    const underLeewayHeights = currentColumnHeights.filter((columnHeight => columnHeight <= currentColumnHeights[0] + leeway))
    const chosenColumn = Math.min(...underLeewayHeights.map(columnHeight => columnHeights.indexOf(columnHeight)))

    rectColumns[chosenColumn].push({ height: rect, int: parseInt(rectI) + 1 })
    columnHeights[chosenColumn] += rect
  }
  return rectColumns
}

const BoardgamesGrid = () => {
  const [rects, setRects] = useState(new Array(321).fill(0).map(() => Math.floor(Math.random() * 150) + 100))
  const columns = 6

  const rectColumns = sortRectsIntoColumns(columns, rects)
  return (
    <Grid container direction="row">
      {new Array(columns).fill(0).map((value, index) => {
        return (
          <Grid
            container
            direction="column"
            key={`column-${index}`}
            sx={{ width: `calc(100% / ${columns})` }}
          >
            {rectColumns[index]?.map(rect => (
              <Box
                key={`box-${rect.int}`}
                sx={{
                  margin: '4px',
                  width: 'calc(100% - 8px)',
                  height: rect.height - 8,
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography color="black">
                  {rect.int}
                </Typography>
              </Box>)
            )}
          </Grid>
        )
      })}
    </Grid >
  )
}

export default BoardgamesGrid;
