import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, Grid, IconButton, InputLabel, Paper, Switch, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { checkScrape, startScrape } from "../../service/backendService";
import { ContentCopy } from "@mui/icons-material";

const Form = () => {
    const [channel, setChannel] = useState('')
    const [authToken, setAuthToken] = useState('')
    const [includeMessages, setIncludeMessages] = useState(true)
    const [includeCalls, setIncludeCalls] = useState(true)
    const [combined, setCombined] = useState(false)

    const [accessKey, setAccessKey] = useState('')

    const [data, setData] = useState({})

    const handleScrape = async () => {
        setData({})
        const scrapeResponse = await startScrape(authToken, channel, includeMessages, includeCalls, combined)
        setAccessKey(scrapeResponse.accessKey)
        navigator.clipboard.writeText(scrapeResponse.accessKey)
        setTimeout(() => {
            alert('Access Key copied to clipboard.')
        }, 100)
    }

    const handleCheck = async () => {
        const statusResponse = await checkScrape(accessKey)
        navigator.clipboard.writeText(JSON.stringify({ all: statusResponse.metadata.allCallDurations, total: statusResponse.metadata.totalCallDuration }))
        setTimeout(() => {
            alert('Debug Data copied to clipboard.\r\nPlease share with James before copying anything else.')
        }, 100)
        setData(statusResponse)
    }

    const handleCopy = (event, copyData, copyType) => {
        event.preventDefault()
        event.stopPropagation()
        navigator.clipboard.writeText(copyData)
        setTimeout(() => {
            alert(`copied ${copyType} to clipboard`)
        }, 100)
    }

    return (
        <Grid sx={{ width: '50%', padding: '8px' }}>
            <Grid container direction="column" gap='16px'>
                <Typography variant="h4">Start Scraper</Typography>
                <Grid container direction="column" gap='8px'>
                    <TextField label="Channel ID" value={channel} onChange={(ev) => { setChannel(ev.target.value) }} />
                    <TextField label="Auth Token" value={authToken} onChange={(ev) => { setAuthToken(ev.target.value) }} />
                    <Grid container direction="row" justifyContent="space-around">
                        <InputLabel>Messages: <Checkbox checked={includeMessages} onChange={(ev) => { setIncludeMessages(ev.target.checked) }} /></InputLabel>
                        <InputLabel>Call Log: <Checkbox checked={includeCalls} onChange={(ev) => { setIncludeCalls(ev.target.checked) }} /></InputLabel>
                        <InputLabel>Seperate<Switch checked={combined} onChange={(ev) => { setCombined(ev.target.checked) }} />Combined</InputLabel>
                    </Grid>
                    <Button variant="contained" onClick={handleScrape}>Scrape</Button>
                </Grid>

                <Typography variant="h4">Check Status</Typography>
                <Grid container direction="column">
                    <TextField label="Access Key" value={accessKey} onChange={(ev) => { setAccessKey(ev.target.value) }} />

                    <Button variant="contained" onClick={handleCheck}>Check Status</Button>
                </Grid>
                <Paper sx={{ padding: '16px', width: '100%' }}>
                    <Typography variant="h5">{`Status: ${data.status || ''}`}</Typography>
                    {
                        data.metadata && (
                            <>
                                <Divider />
                                {data.allCalls && (
                                    <Accordion sx={{ backgroundColor: '#ffffff03' }}>
                                        <AccordionSummary>
                                            <Typography variant="h5">All Calls</Typography>
                                            <IconButton onClick={(event) => handleCopy(event, data.allCalls, 'Call Data')}>
                                                <ContentCopy />
                                            </IconButton>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {data.allCalls.split('\r\n').map((d, index) => <Typography key={index}>{d || "　"}</Typography>)}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {data.allChats && (
                                    <Accordion sx={{ backgroundColor: '#ffffff03' }}>
                                        <AccordionSummary>
                                            <Typography variant="h5">All Messages</Typography>
                                            <IconButton onClick={(event) => handleCopy(event, data.allChats, 'Message Data')}>
                                                <ContentCopy />
                                            </IconButton>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {data.allChats.split('\r\n').map((d, index) => <Typography key={index}>{d || "　"}</Typography>)}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {data.allText && (
                                    <Accordion sx={{ backgroundColor: '#ffffff03' }}>
                                        <AccordionSummary>
                                            <Typography variant="h5">All Messages Including Calls</Typography>
                                            <IconButton onClick={(event) => handleCopy(event, data.allText, 'All Text Data')}>
                                                <ContentCopy />
                                            </IconButton>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {data.allText.split('\r\n').map((d, index) => <Typography key={index}>{d || "　"}</Typography>)}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </>
                        )
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Form;
