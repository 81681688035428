import { Grid, Typography } from "@mui/material";

const Instructions = () => {

    return (
        <Grid sx={{ width: '50%', height: '100%', padding: '8px', overflow: 'auto' }}>
            <Typography variant="h3">Instructions</Typography>
            <Typography variant="h4" color="error">Read This In Entirety First</Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
                These simple instuctions will guide you through using this Discord message scraper. Be aware that to use this tool,
                you will need to provide a valid auth token. These tokens should be kept private at all times and as such, I highly recommend
                revoking your token as soon as this process is complete, The end of these instuctions tells you how.
            </Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
                Also be aware that data is encrypted but is held ephemerally and once it has been provided to you, it is removed from the server,
                if you fail to copy all the data after recieving it, and navigate away from the page, you will have to re-run the scraper.
            </Typography>

            <Typography variant="h4">Getting Your Auth Token</Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
                Getting your Auth Token is a little complicated and once you have it you need to be very careful with it.
                <br></br>
                Firstly, log into Discord in the browser so you can access developer tools (F12).
                <br></br>
                When you have the dev tools open, 
                {" select the network tab. (This may be hidden under the >> menu)"}
                <br></br>
                Near the top of the panel, there will be a filter box, in here type 'science'. (If there are no items listed, you may need to reload the page)
                <br></br>
                Select any of these entries and scroll to the 'Request Headers' section, in that section, find the 'Authorization' header. It will be a combination of about 70 random characters.
                <br></br>
                Copy this string and keep it very safe! We'll revokethis after the scraping process has been completed.
            </Typography>

            <Typography variant="h4">Getting The Channel Id</Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
                You can find the Channel ID as part of the URL when viewing the desired channel, Discord URLs are of the format:
                <br></br>
                {"https://discord.com/channels/<SERVER ID>/<CHANNEL ID>"}
                <br></br>
                In the case of private messages, the server id is always '@me'. So you just have to copy the last part of the path as your Channel Id.
            </Typography>

            <Typography variant="h4">Data Lifecycle and Access Key</Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
                Upon providing the Channel Id and Auth Token, you can start the scrape,
                As this can take some time depending on the number of messages in the channel, you will be provided an Access Key.
                This Access Key can be used to check the status of your scrape, it has 4 states: PENDING, FINISHED, GONE and ERROR.
            </Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
                PENDING means the scrape has started and is in progress, maybe check back a little later.
            </Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
                FINISHED means the scrape has ended and you will be provided the details you have requested. As soon as this status is retrieved, the data is provided to the user.
                At the same time, the data is purged from the server, Any further requests with the same token will return the GONE status code, meaning the data has already been retrieved.
            </Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
                ERROR means the scrape has encountered a problem, it is unable to restart the scrape and any data it has gathered has been removed.
            </Typography>

            <Typography variant="h4">Revoking Your Auth Token</Typography>
            <Typography sx={{ paddingBottom: '8px' }}>
                As soon as this process is completed, you should revokeyour Discord access token. You can easily do this by logging out of the browser
                that you pulled the auth token from, you can test this has been revolked by running a new scrape with the token, you will find that the response for the access key
                is an error.
            </Typography>
        </Grid>
    );
}

export default Instructions;
