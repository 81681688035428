import { Card, Typography, Grid } from "@mui/material";
import SuitIcon from './SuitIcon';
import { useState } from "react";
import { randomInt } from "../../utils/random";
import { wait } from "../../utils/wait";
import { useEffect } from "react";

const allowedValues = [
  'A',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  'J',
  'Q',
  'K',
];

const suitOrder = [
  'Spades',
  'Hearts',
  'Clubs',
  'Diamonds'
]
const PlayingCardRandom = () => {
  const [card, setCard] = useState({suit: 'Spades', value: 'A'})
  const textColor = (['Hearts', 'Diamonds'].includes(card.suit) ? 'red' : 'black')
  const textStyle = { fontSize: '18px', fontWeight: '700', paddingTop: '4px', paddingLeft: '4px' }

  const regen = async () => {
    await wait(100);
    const newSuit = suitOrder[randomInt(0, 3)]
    const newValue = allowedValues[randomInt(0, 12)]
    setCard({suit: newSuit, value: newValue});
  }
  useEffect(() => {
    regen()
  }, [card])
  return (
    <Card sx={{ backgroundColor: 'white', color: textColor, width: '80px', height: '120px' }}>
      <Grid container direction="column">
        <Typography sx={textStyle}>{card.value}</Typography>
        <SuitIcon sx={{ fontSize: '60px', alignSelf: 'center' }} suit={card.suit} />
        <Typography sx={{ ...textStyle, transform: 'rotate(180deg)' }}>{card.value}</Typography>
      </Grid>
    </Card>
  )
}

export default PlayingCardRandom;
