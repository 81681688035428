import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setOpen } from '../../redux/boardgameDialog';
import { Typography, Grid, Dialog, DialogTitle, Skeleton, DialogContent, Link } from '@mui/material';
import { useEffect } from 'react';
import { fetchThingDetails } from '../../service/backendService';

const BoardgamesDialog = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({})
  const { game, open } = useSelector(state => state.boardgameDialog)

  const updateDetails = async (thingId) => {
    const detailsResponse = await fetchThingDetails(thingId)
    if (detailsResponse) setDetails(detailsResponse)
  }

  useEffect(() => {

    if (game && open) {

      updateDetails(game.bggId)
    } else {
      setDetails({})
    }
  }, [game, open])

  const handleClose = () => {
    dispatch(setOpen(false));
  }
  return (
    <Dialog open={open} onClose={handleClose} width="400px">
      <DialogTitle>
        <Grid>
          {(game.name)
            ? (
              <>
                {`${game.name} (${game.bggYear})`}
                <br />
                <Link href={`https://boardgamegeek.com/thing/${game.bggId}`} target='_blank'>
                  Open on BGG
                </Link>
              </>
            )
            : (
              <>
                <Skeleton width="350px" />
                <Link href={`https://boardgamegeek.com/thing/${game.bggId}`}>
                  {'Open on BGG'}
                </Link>
              </>
            )
          }
        </Grid>
      </DialogTitle >
      <DialogContent>
        <Grid container direction="column">
          {(game.urlImage) ? <img src={game.urlImage} style={{ width: '250px' }} alt={`boxart for ${game.name}`} /> : <Skeleton variant="rectangular" height="250px" width="250px" />}
          <Typography>Players: {(game.minPlayerCount) ? game.minPlayerCount + "-" + game.maxPlayerCount : <Skeleton width="350px" />}</Typography>
          <Typography>Play time: {(game.minPlayTime) ? game.minPlayTime + "-" + game.maxPlayTime : <Skeleton width="350px" />}</Typography>
          {(details.description) ? details.description.split('&amp;#10;').map((i, index) => <Typography key={`desc-${index}`} sx={{ padding: '4px 0' }}>{i}</Typography>) : <Skeleton variant="rectangular" height="50px" width="350px" />}
          {/* <Typography>Category: {(game.minplayers) ? game.category.map(listMap).join(", ") : <Skeleton width="350px" />}</Typography>
      <Typography>Mechanic: {(game.minplayers) ? game.mechanic.map(listMap).join(", ") : <Skeleton width="350px" />}</Typography>
      <Typography>Family: {(game.minplayers) ? game.family.map(listMap).join(", ") : <Skeleton width="350px" />}</Typography> */}
        </Grid>
      </DialogContent>
    </Dialog >
  )
}

export default BoardgamesDialog;