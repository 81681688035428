import React from 'react';
import { Grid, Card } from "@mui/material";
import { useDispatch } from "react-redux";
import { setPageTitle } from '../../redux/app';
import Webcam from './Webcam';
import Dice from './Dice';


const GoDicePage = () => {
  const dispatch = useDispatch();
  dispatch(setPageTitle('GoDice Demo'));

  return (
    <Grid container justifyContent="center">
      <Card sx={{margin: '8px', padding: '8px', height: 'fit-content'}}>
        <Webcam />
      </Card>
      <Card sx={{margin: '8px', padding: '8px'}}>
        <Dice />
      </Card>
    </Grid>
  );
}

export default GoDicePage;
