import { Grid, Select, MenuItem, TextField, Button, Switch, InputLabel, FormControl } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setValues } from "../../redux/diceRoller";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { goto } from "../../redux/app";

const DiceOption = () => {
  const optionsSet = useSelector(state => state.dice.set);
  const location = useLocation();
  const searchesMap = {}
  if (location.search) {
    const searches = location.search.substr(1).split("&");
    searches.map((search) => {
      const [key, value] = search.split("=")
      searchesMap[key] = value;
      return [key, value]
    });
  }
  const [faceValue, setFaceValue] = useState(searchesMap.dice || 'D6');
  const [advanced, setAdvanced] = useState(false);
  const [diceCount, setDiceCount] = useState(searchesMap.count || '4');
  const [maxRolls, setMaxRolls] = useState(searchesMap.rolls || '6');
  const [seed, setSeed] = useState(searchesMap.seed || '');
  const dispatch = useDispatch();

  const handleDiceCount = (e) => {
    const maxValue = 16;
    const value = parseInt(e.target.value.replace(/[^0-9]/g, '').substr(0, 2), 10);
    setDiceCount(Math.min(maxValue, value) || '');
  }

  const handleMaxRolls = (e) => {
    const maxValue = 16;
    const value = parseInt(e.target.value.replace(/[^0-9]/g, '').substr(0, 2), 10);
    setMaxRolls(Math.min(maxValue, value) || '');
  }
  const handleDiceFace = (e) => {
    setFaceValue(e.target.value)
  }

  const handleSeed = (e) => {
    setSeed(e.target.value.replace(/[^0-9A-Za-z]/g, '').substr(0, 8));
  }

  const setDiceOptions = () => {
    dispatch(goto('/dice', 'Dice Roller'))
    dispatch(setValues({ dice: faceValue, count: diceCount, seed, maxRolls }))
  }

  const toggleAdvanced = (e) => {
    setAdvanced(e.target.checked)
  }

  const startDisabled = !diceCount;

  return (
    <>
      <Grid sx={{ margin: '8px' }}>
        <FormControl>
          <InputLabel id="dice-type-label">Dice Type</InputLabel>
          <Select autoWidth label="Dice Type" labelId="dice-type-label" id="a" disabled={optionsSet} defaultValue="D6" value={faceValue} onChange={handleDiceFace}>
            <MenuItem value="D6">D6</MenuItem>
            <MenuItem value="RRI">Railroad Ink</MenuItem>
          </Select>
        </FormControl>
        <TextField sx={{ width: '110px' }} label="Dice Count" disabled={optionsSet} value={diceCount} onChange={handleDiceCount} />
        <TextField sx={{ width: '110px' }} label="Max Rolls" disabled={optionsSet} value={maxRolls} onChange={handleMaxRolls} />
        <TextField sx={{ width: '110px' }} label="Seed" disabled={optionsSet} value={seed} onChange={handleSeed} />
        <Switch checked={advanced} onChange={toggleAdvanced} />
      </Grid>
      {advanced &&
        <Grid sx={{ margin: '8px' }}>
          <FormControl>
            <InputLabel id="drop-keep-label">Drop/Keep</InputLabel>
            <Select label="Drop/Keep" labelId="drop-keep-label" id="b" disabled={optionsSet} defaultValue="al">
              <MenuItem value="al">Keep All</MenuItem>
              <MenuItem value="dl">Drop Lowest</MenuItem>
              <MenuItem value="kl">Keep Lowest</MenuItem>
              <MenuItem value="dh">Drop Highest</MenuItem>
              <MenuItem value="kh">Keep Highest</MenuItem>
            </Select>
          </FormControl>
          <TextField sx={{ width: '110px' }} label="Drop/Keep Count" />
        </Grid>
      }
      {!optionsSet &&
        <Button disabled={startDisabled} onClick={setDiceOptions}>Start Rolling</Button>
      }
    </>
  );
}

export default DiceOption;
